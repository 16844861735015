import { HwTypesResponseDTO, IHwTypeBase } from "@dto/architecture.dto";
import { ClassKey } from "@dto/constants/class.constants";
import { GroupCustomGroupFolder, GroupCustomGroupFoldersDTO } from "@dto/customGroup.dto";
import { GroupDashboardFolder, GroupDashboardFoldersDTO } from "@dto/dashboard.dto";
import { MenuItem, MenuLink, MenuResponseDTO } from "@dto/menuResponse.dto";
import { GroupStorageGroupFolder, GroupStorageGroupsResponseDTO } from "@dto/storageGroups.dto";
import axios, { AxiosError } from "axios";
import { API_URL } from "../data/Api";
import { isClassNetwork } from "../data/Class";
import { Label } from "../data/Label";
import { RoutePath } from "../data/Routes";
import { MenuNode } from '../model/MenuNode';
import { Log } from '../service/Log';
import { GLOB } from "../util/Glob";
import { Deferred } from "../util/deferred";

const PATH_PAGE = '/page';
const PATH_SEP = '/';
const KEY_GD = 'gd';
const KEY_GCG = 'gcg';
const KEY_GSG = 'gsg';


export class MenuService {

  readonly hwTypesLoaded = new Deferred<IHwTypeBase[]>();
  readonly menuFiltered = new Deferred<IHwTypeBase[]>();

  /**
   * Storages by device, type and group
   */
  readonly storagesBy: Record<'device' | 'type' | 'group', MenuNode> = {
    device: { key: RoutePath.DEVICE, keyClass: ClassKey.STORAGE, title: 'Storage devices', path: RoutePath.DEVICE, children: [] },
    type: { key: RoutePath.TYPE, keyClass: ClassKey.STORAGE, title: 'Storage types', path: RoutePath.TYPE, children: [] },
    group: { key: RoutePath.GROUP, keyClass: ClassKey.STORAGE, title: 'Storage groups', path: RoutePath.GROUP, children: [] }
  };

  private _hwTypes: IHwTypeBase[] = [];
  private _deviceClasses: MenuItem[] = [];

  get activeClasses() {
    return this._deviceClasses.filter(mi => mi.isActive);
  }

  get allClasses() {
    return [...this._deviceClasses];
  }

  get hwTypes() { return this._hwTypes; }

  getRouteLink(url: string, tab?: string, subTab?: string) {
    if (tab && !url.includes('tab=')) {
      tab = '#tab=' + encodeURIComponent(tab);
      if (subTab)
        subTab = '&subTab=' + encodeURIComponent(subTab);
      else
        subTab = '';
      tab += subTab;
    }
    else {
      tab = '';
    }

    if (url.startsWith(API_URL.CUSTOM_GROUP_MENU + PATH_SEP)) {
      const idx = url.lastIndexOf('/');
      const id = url.substring(idx);
      return RoutePath.CUSTOM_GROUPS + id + tab;
    }
    if (url.startsWith(API_URL.MENU + PATH_PAGE + RoutePath.STORAGES + PATH_SEP)) {
      const idx = url.lastIndexOf('/');
      const id = url.substring(idx);
      return RoutePath.STORAGE + RoutePath.DEVICE + id + tab;
    }
    if (url.startsWith(API_URL.MENU + PATH_PAGE + RoutePath.SAN + PATH_SEP)) {
      const idx = url.lastIndexOf('/');
      const id = url.substring(idx);
      return RoutePath.SAN + RoutePath.TYPE + id + tab;
    }
    if (url.startsWith(API_URL.MENU + PATH_PAGE + RoutePath.LAN_CFG + PATH_SEP)) {
      const idx = url.lastIndexOf('/');
      const id = url.substring(idx);
      return RoutePath.LAN_CFG + RoutePath.TYPE + id + tab;
    }
    const nav = url.replace(MenuLink.API_URL_PAGE, '');
    const sep = nav.indexOf('/');
    const type = nav.substring(0, sep);
    const typeUpper = type;
    const item = nav.substring(sep);
    for (const ht of this._hwTypes) {
      if (ht.hw_type === typeUpper) {
        let allType = '', group = '';
        if (isClassNetwork(ht.class))
          allType = RoutePath.TYPE;
        else if ('/' + ht.class === GLOB.selectedClass) {
          allType = GLOB.selectedAllType;
          group = GLOB.selectedGroup;
        } else if (ht.class === ClassKey.STORAGE) {
          allType = RoutePath.TYPE;
        }
        // else if (isClassSTOR(ht.class)) {
        //   allType = GLOB.selectedAllType || RoutePath.DEVICE;
        // }
        return `/${ht.class}${allType || ''}${group ? '/' + group : ''}/${type}${item}${tab}`;
      }
    }
    if ('/' + type === RoutePath.STORAGE) {
      return `/${type}${RoutePath.DEVICE}${item}${tab}`;
    }

    return `/${nav}${tab}`;
  }

  getDashMenu() {
    return GLOB.getMenu().find(mn => mn.key === RoutePath.DASHBOARD);
  }

  init() {
    this.hwTypesLoaded.reset();
    axios.get<HwTypesResponseDTO>(API_URL.CONFIGURATION + '/hw_types').then(response => {
      this._hwTypes = response.data.data;
      this.hwTypesLoaded.resolve(this._hwTypes);
    }, (reason: AxiosError) => {
      Log.error('Failed to get HW types!', reason);
      this.hwTypesLoaded.reject(reason);
    });

    const topMenu: MenuNode[] = [];
    const menuRequests = [
      axios.get<MenuResponseDTO>(API_URL.DASHBOARD_MENU),
      axios.get<GroupDashboardFoldersDTO>(API_URL.DASHBOARD_MENU_GROUP),
      axios.get<MenuResponseDTO>(API_URL.CUSTOM_GROUP_MENU),
      axios.get<GroupCustomGroupFoldersDTO>(API_URL.CUSTOM_GROUP_MENU_GROUP),
      axios.get<MenuResponseDTO>(MenuItem.API_URL_GEN),
      axios.get<MenuResponseDTO>(API_URL.STORAGE_GROUPS),
      axios.get<GroupStorageGroupsResponseDTO>(API_URL.STORAGE_GROUP_GROUPS),
      axios.get<MenuResponseDTO>(API_URL.MENU + RoutePath.FOLDER + RoutePath.CLASS + RoutePath.STORAGE)
    ] as const;

    topMenu.push({ key: RoutePath.DASHBOARD, title: Label.DASHBOARD, path: RoutePath.DASHBOARD });

    topMenu.push({ key: RoutePath.CUSTOM_GROUPS, title: Label.CUSTOM_GROUPS, path: RoutePath.CUSTOM_GROUPS });

    return Promise.all(menuRequests).then(responses => {

      const dashMenu = this.createGroupMenu(responses[0].data.data, responses[1].data.data, KEY_GD);
      this.positionDashboardMenu(dashMenu);
      topMenu[0].children = dashMenu;

      topMenu[1].children = this.createGroupMenu(responses[2].data.data, responses[3].data.data, KEY_GCG);

      this._deviceClasses = responses[4].data.data;
      const isSomeActive = this._deviceClasses.some(c => c.isActive);
      for (const cls of this._deviceClasses) {
        if (isSomeActive && !cls.isActive) continue;
        topMenu.push({ key: cls.class, title: cls.title, path: '/' + cls.class, url: '/' + cls.class, notActive: !cls.isActive, children: cls.children?.map(MenuService.menuItemToMenuNode) });
      }

      const topStorage = topMenu.find(mn => mn.key === ClassKey.STORAGE);
      if (topStorage) {
        this.storagesBy.type.children = topStorage.children;
        this.storagesBy.device.children = responses[responses.length - 1].data.data.map((mi: MenuItem) => MenuService.menuItemToMenuNode(mi));
        this.storagesBy.group.children = this.createGroupMenu(responses[5].data.data, responses[6].data.data, KEY_GSG);
      }

      GLOB.setMenu(topMenu);

    }, (reason: string) => {
      Log.error('Failed to get class sub-menus!', reason);
      return Promise.reject(new Error(reason));
    });
  }

  private createGroupMenu(personal: MenuItem[], group: (GroupDashboardFolder | GroupCustomGroupFolder | GroupStorageGroupFolder)[], keyPrefix: string) {
    const menu = personal.map(mi => MenuService.menuItemToMenuNode(mi)).sort(MenuService.getFolderSorter());
    if (group?.length) menu.push(MenuNode.getDivider(MenuNode.GROUPS_DIVIDER_KEY, false));
    menu.push(...group.map(mi => {
      const mn = MenuService.menuItemToMenuNode(mi);
      mn.children?.sort(MenuService.getFolderSorter());
      return ({ ...mn, key: keyPrefix + mi.group_id, itemId: mi.group_id + '' });
    }).sort(MenuService.getFolderSorter()));

    return menu;
  }

  private positionDashboardMenu(nodes: MenuNode[]) {

    nodes.unshift(MenuNode.getDivider('dash-overview', false));

    for (const excludeID of MenuNode.DASH_EXCLUDED_ITEM_IDS) {
      const hmIdx = nodes.findIndex(mn => mn.itemId === excludeID);
      if (hmIdx > 0) {
        nodes.unshift(...nodes.splice(hmIdx, 1));
      }
    }
  }

  private static menuItemToMenuNode(mi: MenuItem): MenuNode {
    return {
      ...MenuNode.menuItemToNode(mi), path: mi.isLeaf ? null : GLOB.getItemPath(mi)
    };
  }

  static getFolderSorter(foldersLast = false, pageType?: string) {
    const folderOrder = foldersLast ? -1 : 1;
    return (a: MenuNode, b: MenuNode) => {
      if (pageType && (a.pageType && !a.pageType.includes(pageType) || b.pageType && !b.pageType.includes(pageType))) return 0;
      if (a.isLeaf) {
        if (b.isLeaf) return GLOB.naturalSort(a.title, b.title);
        return folderOrder;
      }
      if (b.isLeaf) return -folderOrder;
      return GLOB.naturalSort(a.title, b.title);
    }
  }

  reloadCustomGroups() {
    Promise.all([axios.get<MenuResponseDTO>(API_URL.CUSTOM_GROUP_MENU),
    axios.get<GroupCustomGroupFoldersDTO>(API_URL.CUSTOM_GROUP_MENU_GROUP)]).then(responses => {
      const menu = GLOB.getMenu();
      menu[1].children = this.createGroupMenu(responses[0].data.data, responses[1].data.data, KEY_GCG);
      GLOB.setMenu([...menu]);
    }, (reason: AxiosError) => Log.error('Failed to refresh custom groups menu!', reason));
  }

  reloadDashboards() {
    return Promise.all([axios.get<MenuResponseDTO>(API_URL.DASHBOARD_MENU),
    axios.get<GroupDashboardFoldersDTO>(API_URL.DASHBOARD_MENU_GROUP)]).then(responses => {
      const groupMenu = this.createGroupMenu(responses[0].data.data, responses[1].data.data, KEY_GD);
      this.positionDashboardMenu(groupMenu);
      const menu = GLOB.getMenu();
      menu[0].children = groupMenu;
      GLOB.setMenu([...menu]);
    }, (reason: AxiosError) => Log.error('Failed to refresh dashboards!', reason));
  }

  reloadStorageGroups() {
    Promise.all([axios.get<MenuResponseDTO>(API_URL.STORAGE_GROUPS),
    axios.get<GroupStorageGroupsResponseDTO>(API_URL.STORAGE_GROUP_GROUPS)]).then(responses => {
      this.storagesBy.group.children = this.createGroupMenu(responses[0].data.data, responses[1].data.data, KEY_GSG);
    }, (reason: AxiosError) => Log.error('Failed to refresh storage groups menu!', reason));
  }
}
