import { ExclamationCircleOutlined } from "@ant-design/icons";
import { HEATMAP_TAB_TITLE } from "@dto/constants/dashboard.constants";
import { BOX_TYPE } from "@dto/constants/pageResponse.constants";
import { CustomGroupFavoriteResponseDTO } from "@dto/customGroup.dto";
import { Box, BoxType, SubTab, Tab, TabResponseDTO } from "@dto/pageResponse.dto";
import { AxiosError } from "axios";
import { FC, RefObject, Suspense, lazy, useEffect, useMemo, useRef, useState } from "react";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { BreadcrumbXM } from "src/component/breadcrumb/BreadcrumbXM";
import { getCgFavoriteURL } from "src/component/breadcrumb/CgHeatmapFavorite";
import { Help } from "src/component/help/Help";
import { Spinner } from "src/component/spinner/Spinner";
import TabsSticky from "src/component/stickyTab/TabsSticky";
import { RoutePath } from "src/data/Routes";
import { appGlobalSlice } from "src/redux/appGlobalStore";
import { useAppDispatch } from "src/redux/hooks";
import { Log } from "src/service/Log";
import { GLOB } from "src/util/Glob";
import { TextUtil } from "src/util/TextUtil";
import { getApi } from "src/util/apiCalls";
import ExportContentIcon from "../../component/apiDownloader/ExportContentIcon";

interface Props {
  tab: Tab;
  url?: string;
  renderBreadcrumb?: boolean;
  topTabName?: string;
}

export function getTabKey(tab: Tab) {
  return TextUtil.removeWhitespace(tab?.title);
}

const BoxPage = ({ tab: { title, content, lazyContent, export: exportProp, help, subTabs }, url, renderBreadcrumb = true, topTabName }: Props) => {
  const [loadedTab, setLoadedTab] = useState<Tab<Box>>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const ac = new AbortController();
    if (lazyContent) {
      //TODO: React19 use(resource)
      getApi<TabResponseDTO>(lazyContent, ac.signal).then(response => {
        setLoadedTab(response.data.data);
      }, (reason: AxiosError) => Log.error('Failed to load lazy tab!', reason));
    }
    if (GLOB.selectedClass === RoutePath.CUSTOM_GROUPS && title === HEATMAP_TAB_TITLE) {
      getApi<CustomGroupFavoriteResponseDTO>(getCgFavoriteURL(), ac.signal).then(resp => {
        dispatch(appGlobalSlice.actions.setCgHeatDash(resp.data.data.favorite));
      }, (reason: AxiosError) => Log.error('Failed to get CG heatmap favorite!', reason));
    }
    return () => {
      ac.abort();
    }
  }, []);


  if (lazyContent) {
    if (loadedTab)
      return <BoxPage tab={loadedTab} url={url} />;
    else
      return <Spinner />;
  }
  if (!content)
    return <div>unknown content</div>;
  if (subTabs) {
    return <SubTabs url={url} subTabs={subTabs} topTabName={title} />;
  }

  return (
    <>
      {exportProp && shouldRenderExportIcon(content) ? <ExportContentIcon exportProp={exportProp} /> : null}
      {renderBreadcrumb && shouldRenderBreadcrumb(content) ?
        <BreadcrumbXM key='xmBC' /> : null}
      {help && <Help href={help.url} title={help.text} className="xm-tab-help" />}
      {resolveBoxComponents(content, title, url, topTabName)}
    </>
  );
};

const SubTabs: FC<{ subTabs: SubTab[], url: string, topTabName: string }> = ({ subTabs, url, topTabName, ...props }) => {
  const [hash, setHash] = useState<{ tab?: string; subTab?: string }>({});
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef<HTMLDivElement>(null)
  const isVisible = useOnScreen(ref)

  useEffect(() => {
    let lochash = hash;
    if (location.hash) {
      lochash = parseHash(location);
      setHash(lochash);
    }
    if (!lochash.subTab) {
      setActiveTab(hash.subTab || getTabKey(subTabs[0]), lochash);
    }
  }, []);

  useEffect(() => {
    if (!hash.subTab) return;
    const lochash = parseHash(location);
    if (isVisible) {
      if (!lochash.subTab)
        setActiveTab(hash.subTab || getTabKey(subTabs[0]), lochash);
    } else if (lochash.subTab) {
      delete lochash.subTab;
      const hashStr = hashRecordToStr(lochash);
      navigate(decodeURIComponent(location.pathname + location.search) + hashStr, { replace: true });
    }

  }, [isVisible, hash]);

  function setActiveTab(tabKey: string, lochash = hash) {
    if (tabKey)
      lochash.subTab = tabKey;
    else
      delete lochash.subTab;

    const hashStr = hashRecordToStr(lochash);
    navigate(decodeURIComponent(location.pathname + location.search) + hashStr, { replace: true });
    setHash({ ...lochash });
  }

  function hashRecordToStr(hashObj: Record<string, string>) {
    const hashes = [];
    for (const key in hashObj) {
      if (Object.hasOwn(hashObj, key)) {
        hashes.push(`${key}=${encodeURIComponent(String(hashObj[key]))}`);
      }
    }
    const hashStr = hashes.length ? '#' + hashes.join('&') : '';
    return hashStr;
  }

  return <div className="xm-subtabs" ref={ref}>
    <TabsSticky activeKey={hash.subTab} onChange={setActiveTab}
      items={subTabs.map(st => ({
        key: getTabKey(st),
        label: st.title,
        children: <Suspense fallback={<Spinner />}><BoxPage tab={st} url={url} topTabName={topTabName} /></Suspense>
      }))} />
  </div>;
}

function useOnScreen(ref: RefObject<HTMLElement>) {

  const [isIntersecting, setIntersecting] = useState(false)

  const observer = useMemo(() => new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting)
  ), [ref])


  useEffect(() => {
    observer.observe(ref.current)
    return () => observer.disconnect()
  }, [])

  return isIntersecting
}

export function parseHash(location: Location): Record<string, string> {
  return location.hash.substring(1).split('&').map(kv => kv.split('='))
    .reduce((prev, [key, value]) => ({ ...prev, [key]: TextUtil.removeWhitespace(decodeURIComponent(value)) }), {});
}

export function resolveBoxComponents(boxes: Box[], tabName: string, url: string, topTabName?: string) {
  return boxes.map((b, index) => <BoxComponent type={b.type} key={`${b.type}${index}`} box={b} tabName={topTabName || tabName} subTabName={topTabName ? tabName : null} url={url} />);
}

const shouldRenderBreadcrumb = (content: Box[]) => content.every(({ type }) => !(NO_BREADCRUMB as readonly string[]).includes(type));
//const isCenter = (content: Box[]) => content.some(({ type }) => (CENTER_BOX as ReadonlyArray<string>).includes(type));
const shouldRenderExportIcon = (content: Box[]) => content.some(({ type }) => !(NO_EXPORT_ICON as readonly string[]).includes(type));

const NO_BREADCRUMB = [
  BOX_TYPE.TopologyRequestBox,
  BOX_TYPE.PortHealthStatusBox,
] as const;

// const CENTER_BOX = [
//   BOX_TYPE.FourGraphBox,
// ] as const;

const NO_EXPORT_ICON = [
  BOX_TYPE.OverviewBox,
] as const;

interface BoxComponentProps {
  type: BoxType;
  tabName: string;
  box: Box;
  url?: string;
  subTabName?: string;
}

const BoxComponent = ({ type, ...props }: BoxComponentProps) => {
  if (type === 'RegroupButtonBox') return null;

  if (!boxComponent[type])
    return <MissingBox type={type} />

  const BoxOfType = boxComponent[type];

  if (type !== BOX_TYPE.TopologyRequestBox && type !== BOX_TYPE.OverviewBox)
    return (
      <div className="text-center">
        <BoxOfType {...props} />
      </div>
    );

  return <BoxOfType {...props} />
};

const boxComponent = {
  [BOX_TYPE.CapacityTableBox]: lazy(() => import('src/boxParts/table/TableCapacityPart')),
  [BOX_TYPE.CustomTopBox]: lazy(() => import('src/boxParts/customTop/CustomTopPart')),
  [BOX_TYPE.DashboardBox]: lazy(() => import('src/boxParts/dashboard/DashboardPart')),
  [BOX_TYPE.FourGraphBox]: lazy(() => import('src/boxParts/graph/FourGraphPart')),
  [BOX_TYPE.HeatmapBox]: lazy(() => import('src/boxParts/heatmap/HeatmapPart')),
  [BOX_TYPE.HtmlTextBox]: lazy(() => import('src/boxParts/HtmlPart')),
  [BOX_TYPE.MirrorBox]: lazy(() => import('src/boxParts/mirror/MirrorPart')),
  [BOX_TYPE.MultiGraphBox]: lazy(() => import('src/boxParts/graph/MultiGraphPart')),
  [BOX_TYPE.OverviewBox]: lazy(() => import('src/boxParts/overview/OverviewPart')),
  [BOX_TYPE.PortHealthStatusBox]: lazy(() => import('src/boxParts/sanHealthStatus/SanHealthStatus')),
  [BOX_TYPE.PredictionGraphBox]: lazy(() => import('src/boxParts/graph/PredictionGraphPart')),
  [BOX_TYPE.RegroupedGraphBox]: lazy(() => import('src/boxParts/graph/RegroupedGraphPart')),
  [BOX_TYPE.SingleGraphBox]: lazy(() => import('src/boxParts/graph/SingleGraphPart')),
  [BOX_TYPE.SingleTimeRangeGraphBox]: lazy(() => import('src/boxParts/graph/SingleTimeRangeGraphPart')),
  [BOX_TYPE.StorageHeatmapBox]: lazy(() => import('@parts/heatmap/StorageHeatmapPart')),
  [BOX_TYPE.TableBox]: lazy(() => import('src/boxParts/table/TablePart')),
  [BOX_TYPE.TablePivotBox]: lazy(() => import("src/boxParts/table/TablePivotPart")),
  [BOX_TYPE.TextBox]: lazy(() => import('src/boxParts/TextPart')),
  [BOX_TYPE.TopBox]: lazy(() => import('src/boxParts/table/TableTopGroupPart')),
  [BOX_TYPE.TopologyRequestBox]: lazy(() => import('src/boxParts/topology/TopologyPart')),
  [BOX_TYPE.TableGroupBox]: lazy(() => import('src/boxParts/table/TableGroupPart')),
  [BOX_TYPE.TableHeaderGroupBox]: lazy(() => import('src/boxParts/table/TableHeaderGroupPart')),
  [BOX_TYPE.ConfigurationChangesBox]: lazy(() => import('src/boxParts/cfgTracker/CfgTrackerPart')),
} as const;

const MissingBox = ({ type }: { type: BoxType }) => <div><ExclamationCircleOutlined style={{ fontSize: '1.5em' }} className="text-danger" /> No component for {type}</div>;

export default BoxPage;