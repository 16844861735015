import { HEATMAP_FOLDER_ITEM_ID } from '@dto/constants/dashboard.constants';
import { HEALTH_STATUS_ITEM_ID, HEATMAP_ITEM_ID } from '@dto/constants/healthStatus.constants';
import { AdminFeatures } from '@dto/groupsResponse.dto';
import { IMenuItem, MenuItem } from '@dto/menuResponse.dto';
import { DataNode } from 'antd/lib/tree';
import { ReactNode } from 'react';
import { ClassType } from 'src/data/Class';
import { GLOB } from 'src/util/Glob';

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
export interface MenuNode extends DataNode, IMenuItem {
  path?: string;
  keyClass?: ClassType;
  parent?: MenuNode;
  children?: MenuNode[];
  component?: JSX.Element;
  divider?: boolean;
  titleText?: string;
  title?: ReactNode;
  selectable?: boolean;
  group_id?: number;


  // ============== filters

  notInDemo?: boolean;
  notInProduction?: boolean;
  notOnDocker?: boolean;
  hwType?: string | string[];
  classType?: ClassType;
  notActive?: boolean;
  adminOnly?: boolean;
  adminFeature?: keyof AdminFeatures;
  blockReadonly?: boolean;
  treeOnly?: boolean;

  isSearchTree?: boolean;
}

const DIVIDER_ITEM_ID = 'divider';

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
export abstract class MenuNode {

  static readonly DASH_EXCLUDED_ITEM_IDS = [HEALTH_STATUS_ITEM_ID, HEATMAP_FOLDER_ITEM_ID, HEATMAP_ITEM_ID];
  static readonly DASH_EXCLUDED_ITEM_IDS_ALL = [...MenuNode.DASH_EXCLUDED_ITEM_IDS, DIVIDER_ITEM_ID];
  static readonly GROUPS_DIVIDER_KEY = 'groups-divider';

  static isDashExcluded(node: MenuNode) {
    if (this.DASH_EXCLUDED_ITEM_IDS_ALL.includes(node.itemId)) return true;
    if (node.parent) {
      return MenuNode.isDashExcluded(node.parent) as boolean;
    }
    return false;
  }

  static getDivider(key: string, treeOnly: boolean): MenuNode {
    return {
      key,
      itemId: DIVIDER_ITEM_ID,
      title: <hr />,
      className: 'xm-divider',
      selectable: false,
      adminOnly: true,
      divider: true,
      isLeaf: true,
      treeOnly
    };
  }

  static getGroupId(item: MenuNode): number {
    if (!item) return;
    if (item.group_id) return item.group_id;
    return MenuNode.getGroupId(item.parent);
  }

  static getGroupPathSegment(group: MenuNode) {
    return group.itemId;
  }

  static getNodePath(node: MenuNode) {
    return node.path ? node.path : GLOB.getItemPath(node);
  }

  /**
   * Get array of parent node keys including self
   * @param node
   * @returns
   */
  static getParentKeys(node?: MenuNode): string[] {
    if (!node?.parent) return [];
    const parents = MenuNode.getParentKeys(node.parent);
    parents.push(node.key as string);
    return parents;
  }

  /**
   * Get array of parent nodes from top to self
   * @param node
   * @returns
   */
  static getParentNodes(node: MenuNode): MenuNode[] {
    if (!node) return [];
    const arr = MenuNode.getParentNodes(node.parent)
    arr.push(node);
    return arr;
  }

  /**
   * Gets top parent in tree or stops at condition
   * @param node node which parent to get
   * @param condition optional condition to stop sooner
   * @returns node
   */
  static getTopParent(node: MenuNode, condition?: (node: MenuNode) => boolean): MenuNode {
    if (!node?.parent) return;
    if (condition?.(node)) return node;
    const parent = MenuNode.getTopParent(node.parent, condition);
    if (!parent) return node;
    return parent;
  }

  static findDeepNode(nodes: MenuNode[], item: string, hwType?: string, group?: string): MenuNode {
    if (!nodes) return;
    for (const node of nodes) {
      if (group && !node.group_id && group !== MenuNode.getGroupPathSegment(node) && !node.key.toString().startsWith(group + '/')) continue;
      if (node.itemId === item || '/' + node.itemId === item) return node;
      if (node.pageType === item && (!hwType || !node.url || node.url.includes(hwType))) return node;
      if (node.path?.includes(item)) return node;//check for groups
      if (node.url?.includes(item)) return node;//check for groups
      if (node.children) {
        const result = MenuNode.findDeepNode(node.children, item, hwType, group);
        if (result) {
          return result;
        }
      }
    }
  }

  /**
   * Create MenuNode from MenuItem
   * @param item
   * @param keyPrefix
   * @returns
   */
  static menuItemToNode(item: MenuItem, keyPrefix = ''): MenuNode {
    return {
      ...item,
      key: keyPrefix + item.url,// (item.itemId && typeof item.itemId === 'string' ? item.itemId : item.url),
      titleText: item.title,
      isLeaf: !!item.isLeaf,
      children: item.children?.map(mi => MenuNode.menuItemToNode(mi)),
      //path: GLOB.getItemPath(item.url)
    };
  }

  /**
   * Gets item to select in menu on folder expand
   * @param children
   * @returns
   */
  static selectFirst(children: MenuNode[]) {
    let selected = children.find(mn => mn.isDefault);
    if (!selected) selected = children.find(mn => mn.isLeaf && mn.itemId !== DIVIDER_ITEM_ID);
    if (!selected) selected = children.find(mn => mn.itemId !== DIVIDER_ITEM_ID);
    if (!selected) selected = children[0];
    return selected;
  }

}