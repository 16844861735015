import { BasicResponseDTO } from "./basicResponse.dto"
import { MENU_STYLE_CLASS } from "./constants/menuResponse.constants"

export type MenuStyleClass = (typeof MENU_STYLE_CLASS)[keyof typeof MENU_STYLE_CLASS]

export class MenuLevel {
  title: string
  children?: MenuLevel
  next_level_url?: string
}

export interface IMenuItem {
  url?: string

  hwType?: string | string[]
  subsystem?: string

  itemId?: string
  isLeaf?: boolean
  isDefault?: boolean

  styleClass?: MenuStyleClass

  /**
   * @deprecated remove once page boxes are in use
   */
  pageType?: string
}

export abstract class MenuItem implements IMenuItem {
  static readonly API_URL_GEN = "/api/menu/v1/folder/"

  title: string
  url?: string

  hwType?: string
  subsystem?: string
  children?: MenuItem[]

  itemId?: string
  isLeaf?: boolean
  isDefault?: boolean
  status?: string

  styleClass?: MenuStyleClass

  pageType?: string

  class?: string
  isActive?: boolean
  key?: string
  disabled?: boolean
  checkable?: boolean

  _new?: Newable<any>

  constructor(title: string, path?: string) {
    this.title = title
    if (path !== undefined) {
      this.url = MenuItem.API_URL_GEN + path
    }
  }
}

export class MenuClassFolder extends MenuItem {
  constructor(title: string, pClass: string, isActive?: boolean) {
    super(title)
    this.class = pClass

    if (isActive !== undefined) {
      this.isActive = isActive
    }
  }
}

export type Newable<T> = new (...args: any[]) => T;

export class MenuFolder extends MenuItem {
  constructor(title: string, path: string, hwType?: string, subsystem?: string, itemId?: string, _new?: Newable<any>) {
    if (hwType !== undefined) {
      path = 'hw_type/' + path;
    }
    super(title, path)
    if (hwType != undefined && hwType != null) {
      this.hwType = hwType
    }
    if (subsystem != undefined && subsystem != null) {
      this.subsystem = subsystem
    }
    if (itemId != undefined && itemId != null) {
      this.itemId = itemId
    }
    if (_new !== undefined) {
      this._new = _new;
    }
  }
}

export class MenuLink extends MenuItem {
  static readonly API_URL_PAGE = "/api/menu/v1/page/"
  isLeaf = true

  constructor(
    title: string,
    path: string,
    pageType?: string,
    itemId?: string,
    isDefault?: boolean
  ) {
    super(title, path)
    this.url = MenuLink.API_URL_PAGE + path

    if (pageType != undefined) {
      this.pageType = pageType
    }
    if (itemId != undefined && itemId != null) {
      this.itemId = itemId
    }
    if (isDefault != undefined) {
      this.isDefault = isDefault
    }
  }
}

export class MenuResponseDTO extends BasicResponseDTO {
  declare data: MenuItem[]
}
