import { Item } from '@dto/architecture.dto'
import { ClassKey } from '@dto/constants/class.constants'
import { useState } from 'react'
import { GridScope } from 'src/component/grid/Grid'
import { LinkXM } from 'src/component/link/LinkXM'
import { SelectXM } from 'src/component/select/SelectXM'
import { GLOB } from 'src/util/Glob'
import { nameof } from 'ts-simple-nameof'
import { SelectItemTagRender } from '../tools/alerting/AlertingHistory'
import { classDefinitionLabelSearch, ClassKeySearch } from './Search'

interface Props {
  items: Item[];
  tabSelected: string;
}

function path(selector: (props: Item) => unknown) {
  return nameof<Item>(selector).split('.')
}

const SearchTab = ({ items, tabSelected }: Props) => {
  const [gs] = useState(() => new GridScope<Item>())
  const uniqKey = (item: Item) => item.item_id
  return (
    <gs.Grid
      className='xm-table-fit'
      dataSource={items}
      showSorterTooltip={false}
      pagination={false}
      rowKey={uniqKey}
      renderProps={{ noTooltipColumns: ['tags'] }}
      scroll={{ x: true, y: GLOB.getState().mainScrollRef.current.clientHeight - 65 }}
    >
      <gs.Col
        title='Item'
        dataIndex='label'
        minWidth='5em'
        filter={{ field: ['label'] }}
        render={(text: string, item: Item) => (
          <LinkXM to={GLOB.menuService.getRouteLink(item.url)}>{text}</LinkXM>
        )}
        sorter={(a, b) => GLOB.naturalSort(a.label, b.label)}
        key={'lab'}
      />
      <gs.Col
        title='Root parent'
        dataIndex='root_parent'
        minWidth='9em'
        filter={{ field: ['root_parent'] }}
        /* render={(_: string, item: Item) => (
          <LinkXM to={GLOB.menuService.getRouteLink(`/api/menu/v1/page/${item.root_parent ? item.root_parent.hw_type : item.hw_type}/${item.root_parent ? item.root_parent.subsystem : item.subsystem}?item_id=${item.root_parent ? item.root_parent.item_id : item.item_id}`)}>{item.root_parent ? item.root_parent.label : ''}</LinkXM>
        )} */
        render={(_: string, item: Item) => {
          return item.root_parent_url && item.root_parent ?
            <LinkXM to={GLOB.menuService.getRouteLink(item.root_parent_url)}>{item.root_parent.label}</LinkXM> : ""
        }}
        sorter={(a, b) => GLOB.naturalSort(a.root_parent, b.root_parent)}
        key={'parent'}
      />
      <gs.Col
        title='Subsystem'
        dataIndex='subsystem'
        minWidth='9em'
        filter={{ field: ['subsystem'] }}
        sorter={(a, b) => GLOB.naturalSort(a.subsystem, b.subsystem)}
        key={'subs'}
      />
      <gs.Col
        title='Device class'
        dataIndex='hw_type'
        minWidth='9em'
        filter={{ field: ['hw_type'] }}
        sorter={(a, b) => GLOB.naturalSort(a.hw_type, b.hw_type)}
        key={'hw'}
      />
      <gs.Col
        title='Tags'
        dataIndex={path((t) => t.tags_labels.length)}
        minWidth='12em'
        filter={{ field: ['tags_labels'] }}
        render={(_, item: Item) => (
          <SelectXM
            popupClassName='xm-select-readonly'
            mode='multiple'
            options={
              item.tags_labels
                ? item.tags_labels.map((t) => ({
                  value: t,
                  label: <div className='xm-tree-title'>{t}</div>,
                }))
                : []
            }
            dropdownMatchSelectWidth={true}
            maxTagCount='responsive'
            style={{ width: '100%' }}
            value={item.tags_labels ? item.tags_labels.map((t) => t) : []}
            menuItemSelectedIcon={null}
            removeIcon={null}
            tagRender={SelectItemTagRender}
          />
        )}
        sorter={(a, b) => GLOB.naturalSort(a.tags_labels?.join(', '), b.tags_labels?.join(', '))}
        key={'tags'}
      />
      <gs.Col
        title='IP Addresses'
        dataIndex='ip_addresses'
        minWidth='10em'
        filter={{ field: ['ip_addresses'] }}
        render={(_, item: Item) => (item.ip_addresses ? item.ip_addresses?.join(', ') : '')}
        sorter={(a, b) =>
          GLOB.naturalSort(a.ip_addresses?.join(', '), b.ip_addresses?.join(', '))
        }
        key={'ip'}
      />
      {(tabSelected === classDefinitionLabelSearch[ClassKeySearch.SERVER] || tabSelected === classDefinitionLabelSearch[ClassKey.LAN]) && (
        <gs.Col
          title='MAC Addresses'
          dataIndex='mac_addresses'
          minWidth='11em'
          filter={{ field: ['mac_addresses'] }}
          render={(_, item: Item) => {
            return item.mac_addresses?.join(', ')?.length > 20
              ? `${item.mac_addresses.join(', ').slice(0, 19)} ...`
              : item?.mac_addresses?.join(', ')
          }
          }
          sorter={(a, b) =>
            GLOB.naturalSort(a.mac_addresses?.join(', '), b.mac_addresses?.join(', '))
          }
          key={'mac'}
        />
      )}
      {tabSelected === classDefinitionLabelSearch[ClassKey.STORAGE] && (
        <gs.Col
          title='Volume ID'
          dataIndex='lun_id'
          minWidth='9em'
          filter={{ field: ['lun_id'] }}
          key={'lun'}
          render={(_, item: Item) => (item.lun_id ? item.lun_id?.join(', ') : '')}
          sorter={(a, b) =>
            GLOB.naturalSort(a.lun_id?.join(', '), b.lun_id?.join(', '))
          }
        />
      )}
      {(tabSelected === classDefinitionLabelSearch[ClassKey.STORAGE] || tabSelected === classDefinitionLabelSearch[ClassKey.SAN]) && (
        <gs.Col
          title='WWN'
          dataIndex='wwn'
          minWidth='9em'
          filter={{ field: ['wwn'] }}
          key={'wwn'}
          render={(_, item: Item) => (item.wwn ? item.wwn?.join(', ') : '')}
          sorter={(a, b) =>
            GLOB.naturalSort(a.wwn?.join(', '), b.wwn?.join(', '))
          }
        />
      )}
    </gs.Grid>
  )
}

export default SearchTab
