import { AlertHistory, AlertHistoryDTO, AlertLevel } from "@dto/alerting.dto";
import { ALERT_LEVEL, getAlertLevelIndex } from "@dto/constants/alerting.constants";
import { UnitUtil } from "@dto/util/UnitUtil";
import { uniqueArrayFilterFce } from "@dto/util/util";
import { RangePickerProps } from "antd/lib/date-picker";
import { RangePickerDateProps } from "antd/lib/date-picker/generatePicker";
import { ColumnFilterItem } from "antd/lib/table/interface";
import { AxiosError } from "axios";
import moment from "moment";
import { CustomTagProps } from "rc-select/lib/BaseSelect";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import RangeDatePicker, { RANGE_DATE_PICKER_DEFAULT_RANGES } from "src/component/dataTimePicker/RangeDatePicker";
import { ColProps, GridScope } from "src/component/grid/Grid";
import { SelectXM } from "src/component/select/SelectXM";
import { makeItemLabelRoot } from "src/component/selectItems/children/ModalSelectItems";
import { API_URL } from "src/data/Api";
import { Log } from "src/service/Log";
import { DateUtil } from "src/util/DateUtil";
import { TextUtil } from "src/util/TextUtil";
import { UnitHelper } from "src/util/UnitHelper";
import { getApi } from "src/util/apiCalls";
import { nameof } from "ts-simple-nameof";

export const AlertingHistory: FC = props => {
  const [gs] = useState(() => new GridScope<AlertHistory>);
  const divRef = useRef<HTMLDivElement>();
  const [history, setHistory] = useState<AlertHistory[]>([]);
  const [alertGroupFilter, setAlertGroupFilter] = useState<ColumnFilterItem[]>([]);
  const [metricFilter, setMetricFilter] = useState<ColumnFilterItem[]>([]);
  const [itemFilter, setItemFilter] = useState<ColumnFilterItem[]>([]);
  const [userGroupFilter, setUserGroupFilter] = useState<ColumnFilterItem[]>([]);
  const [targetFilter, setTargetFilter] = useState<ColumnFilterItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [state] = useState(() => {
    const start = new Date();
    start.setDate(start.getDate() - 1);
    const end = new Date();
    return { aborter: null as AbortController, start, end };
  });
  const severityFilter = useMemo<ColumnFilterItem[]>(() => Object.entries(ALERT_LEVEL).map(value => ({ text: value[0], value: value[1] })), []);

  useEffect(() => {
    updateHistory();
    return () => {
      state.aborter.abort();
    }
  }, []);

  function updateHistory() {
    state.aborter?.abort();
    state.aborter = new AbortController();
    setLoading(true);
    getApi<AlertHistoryDTO>(API_URL.ALERTING + '/history', state.aborter.signal,
      { params: { from: state.start, to: state.end } }).then(resp => {
        const hist = resp.data.data;
        hist.sort(triggeredSorter);
        hist.reverse();
        hist.forEach(ah => {
          ah.targets.sort((a, b) => a.length - b.length);
          ah.items.sort((a, b) => a.label.length - b.label.length);
        });
        setHistory(hist);

        const agFilter = hist.filter(uniqueArrayFilterFce((a, b) => a.alerting_group_id === b.alerting_group_id))
          .sort(alertGroupSorter)
          .map(ah => ({ text: ah.alerting_group_label, value: ah.alerting_group_label }));
        setAlertGroupFilter(agFilter);

        const mFilter = hist.filter(uniqueArrayFilterFce((a, b) => a.metric.label === b.metric.label))
          .sort(metricSorter)
          .map(ah => ({ text: ah.metric.label, value: ah.metric.label }));
        setMetricFilter(mFilter);

        const iFilter = hist.flatMap(ah => ah.items).filter(uniqueArrayFilterFce((a, b) => a.label === b.label))
          .sort((a, b) => a.label.localeCompare(b.label))
          .map(i => ({ text: i.label, value: i.label }));
        setItemFilter(iFilter);

        const ugFilter = hist.filter(uniqueArrayFilterFce((a, b) => a.group_label === b.group_label))
          .sort(userGroupSorter)
          .map(ah => ({ text: ah.group_label, value: ah.group_label }));
        setUserGroupFilter(ugFilter);

        const tFilter = hist.flatMap(ah => ah.targets).filter(uniqueArrayFilterFce((a, b) => a === b))
          .sort((a, b) => a.localeCompare(b))
          .map(t => ({ text: t, value: t }));
        setTargetFilter(tFilter);

      }, (reason: AxiosError) => Log.error('Failed to load alert history!', reason))
      .finally(() => setLoading(false));
  }

  const cols: ColProps<AlertHistory>[] = [
    {
      key: 'time',
      title: 'Time',
      minWidth: '8em',
      dataIndex: path(t => t.triggered),
      //defaultSortOrder: 'descend',
      sorter: triggeredSorter,
      render: (value, record) => DateUtil.getShortDateTime(value)
    },
    {
      key: 'alertGroup',
      title: 'Alerting Group',
      minWidth: '12em',
      dataIndex: path(t => t.alerting_group_label),
      sorter: alertGroupSorter,
      filters: alertGroupFilter,
      filterSearch: true,
      onFilter: (value: string, record) => record.alerting_group_label === value,
      render: (value, record) => TextUtil.trimEnd(value, 64)
    },
    {
      key: 'severity',
      title: 'Severity',
      minWidth: '7em',
      dataIndex: path(t => t.level),
      sorter: (a, b) => getAlertLevelIndex(a.level) - getAlertLevelIndex(b.level),
      filters: severityFilter,
      onFilter: (value: AlertLevel, record) => record.level === value,
    },
    {
      key: 'metric',
      title: 'Metric',
      minWidth: '10em',
      dataIndex: path(t => t.metric.label),
      sorter: metricSorter,
      filters: metricFilter,
      filterSearch: true,
      onFilter: (value, record) => record.metric.label === value,
      render: (value, record) => {
        const m = record.metric;
        const prefix = m.defaultPrefix || UnitUtil.sizeUnit(0, m.shortcut, m.unit).unitSizePrefix;
        const prefixShortcut = (prefix || '') + m.shortcut;
        return UnitHelper.getMetricOptionLabel(record.metric.label, prefixShortcut);
      }
    },
    {
      key: 'limit',
      title: 'Limit',
      dataIndex: path(t => t.limit),
      sorter: (a, b) => a.limit - b.limit,
      render: (value, record) => UnitUtil.roundValue(UnitUtil.convertSImetric(record.limit, record.metric), 1, record.metric.unit, record.metric)
    },
    {
      key: 'items',
      title: 'Items',
      minWidth: '12em',
      className: 'xm-col-no-pad',
      dataIndex: path(t => t.items.length),
      filters: itemFilter,
      filterSearch: true,
      onFilter: (value, record) => record.items.some(i => i.label === value),
      render: (value, record) => <SelectXM popupClassName="xm-select-readonly" mode="multiple"
        options={record.items.map(i => ({ value: i.label, label: <div className="xm-tree-title">{makeItemLabelRoot(i)}</div> }))}
        maxTagCount="responsive" style={{ width: '100%' }} value={record.items.map(i => i.label)}
        tagRender={SelectItemTagRender}
        menuItemSelectedIcon={null} removeIcon={null}
      />
    },
    {
      key: 'groupShared',
      title: 'Group Shared',
      minWidth: '10em',
      dataIndex: path(t => t.group_label),
      sorter: userGroupSorter,
      filters: userGroupFilter,
      filterSearch: true,
      onFilter: (value, record) => record.group_label === value
    },
    {
      key: 'targets',
      minWidth: '14em',
      className: 'xm-col-no-pad',
      title: 'Targets',
      dataIndex: path(t => t.targets.length),
      filters: targetFilter,
      filterSearch: true,
      onFilter: (value, record) => record.targets.some(t => t === value),
      render: (value, record) => <SelectXM popupClassName="xm-select-readonly" mode="multiple" options={record.targets.map(t => ({ value: t, label: t }))}
        maxTagCount="responsive" style={{ width: '100%' }} value={record.targets}
        menuItemSelectedIcon={null} removeIcon={null}
      />
    }
  ];

  const handleRangeChange: RangePickerProps['onChange'] = (values) => {
    state.start = values[0].toDate();
    state.end = values[1].toDate();
    updateHistory();
  }

  return <div ref={divRef} className="xm-alert-history">
    <div className="xm-alert-history-range">
      Show alerts within time range: <RangeDatePicker
        ranges={alertHistoryRanges}
        allowClear={false}
        defaultValue={[moment(state.start), moment(state.end)]}
        onChange={handleRangeChange}
      />
    </div>
    <gs.Grid
      className="xm-table-fit"
      dataSource={history}
      rowKey={(r) => r.alerting_history_id}
      columns={cols}
      loading={loading}
      sticky
      renderProps={{ noTooltipColumns: ['items'] }}
      scroll={divRef.current ? { y: divRef.current.closest('.ant-tabs-content').clientHeight - 65 } : null}
    ></gs.Grid>
  </div>;
}

const alertHistoryRanges: RangePickerDateProps<moment.Moment>['ranges'] = {
  'Last Hour': RANGE_DATE_PICKER_DEFAULT_RANGES['Last Hour'],
  'Last 24h': [moment().subtract(1, 'day'), moment()],
  'Last Day': RANGE_DATE_PICKER_DEFAULT_RANGES['Last Day'],
  'Last Week': RANGE_DATE_PICKER_DEFAULT_RANGES['Last Week']
};

export function SelectItemTagRender(props: Readonly<CustomTagProps>) {
  return <span className="ant-select-selection-item">{props.value}</span>;
}

function triggeredSorter(a: AlertHistory, b: AlertHistory) {
  return a.triggered.localeCompare(b.triggered);
}

function alertGroupSorter(a: AlertHistory, b: AlertHistory) {
  return a.alerting_group_label.localeCompare(b.alerting_group_label);
}

function metricSorter(a: AlertHistory, b: AlertHistory) {
  return a.metric.label.localeCompare(b.metric.label);
}

function userGroupSorter(a: AlertHistory, b: AlertHistory) {
  return a.group_label?.localeCompare(b.group_label);
}

function path(selector: (props: AlertHistory) => unknown) {
  return nameof<AlertHistory>(selector).split('.');
}