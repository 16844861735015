import { CloseOutlined } from "@ant-design/icons"
import { Input } from "antd"
import { DataNode, EventDataNode } from "antd/lib/tree"
import React, { Key, useEffect, useState } from "react"
import { MenuNode } from "src/model/MenuNode"
import { itemsToNodes } from "src/routing/NavigateRoute"
import { Log } from "src/service/Log"
import { GLOB } from "src/util/Glob"
import { searchMenuGET } from "./LeftMenuSearchAPI"

interface Props {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  setLoadedKeys: React.Dispatch<React.SetStateAction<Key[]>>
  reloadTree: () => void
  onSelect: (keys: React.Key[],
    info: {
      event: 'select';
      selected: boolean;
      node: EventDataNode<DataNode>;
      selectedNodes: MenuNode[];
      nativeEvent: MouseEvent;
    }) => void
}

const LeftMenuSearch = ({ setIsLoading, reloadTree, setLoadedKeys, onSelect }: Props) => {

  const onFieldClear = () => {

    const selectedNode = { ...GLOB.selectedItem };
    setLoadedKeys([]);
    GLOB.setSelectedKeys([]);
    GLOB.setExpandedKeys([]);
    reloadTree();
    GLOB.setSelectedKeys([selectedNode.key as string]);
    return;
  };

  const [searchText, setSearchText] = useState(null);
  const [oldClass, setOldClass] = useState(null);
  const [oldType, setOldType] = useState(null);

  const walkNodeTree = (node: MenuNode[], expandedKeys: Key[], selectedKeys: Key[]) => {
    for (const item of node) {
      if (item.children) {
        expandedKeys.push(item.key);
        walkNodeTree(item.children, expandedKeys, selectedKeys);

      } else {
        selectedKeys.push(item.key);
      }
    }
  }

  const selectFirstLowerMostNode = (node: MenuNode) => {
    if (node.children) {
      return selectFirstLowerMostNode(node.children[0]);
    } else {
      return node;
    }
  }

  useEffect(() => {
    if (GLOB.selectedClass !== oldClass) {
      setSearchText("");
    }
    setOldClass(GLOB.selectedClass);
  }, [GLOB.selectedClass])

  useEffect(() => {
    if (GLOB.selectedType !== oldType) {
      setSearchText("");
    }
    setOldType(GLOB.selectedType);
  }, [GLOB.selectedType])

  const search = async (value: string) => {
    if (!value || value === "") {
      onFieldClear();
      return;
    }
    setIsLoading(true);
    try {
      GLOB.setSelectedKeys([]);
      GLOB.setExpandedKeys([]);
      setLoadedKeys([]);
      const data = await searchMenuGET(value);
      const expandedKeys = [];
      const selectedKeys = [];
      const nodes = itemsToNodes(data);
      walkNodeTree(nodes, expandedKeys, selectedKeys);
      const toSelect = nodes?.[0] ? selectFirstLowerMostNode(nodes[0]) : null;
      if (toSelect && GLOB.selectedItem !== toSelect) onSelect(null, { selectedNodes: [toSelect], event: 'select', nativeEvent: null, node: null, selected: null });

      GLOB.setExpandedKeys([...expandedKeys]);
      if (toSelect) {
        GLOB.setSelectedKeys([toSelect.key as string]);
      }
      const tree = GLOB.getTree();
      console.log(tree);
      GLOB.setTree({ ...tree, children: nodes });
    } catch (error) {
      console.error(error)
      Log.error(error.toString())
    }
    setIsLoading(false);
  }

  return (
    <Input.Search
      className='item-search'
      allowClear={{
        clearIcon: <CloseOutlined onClick={() => {
          setSearchText("")
          onFieldClear()
        }} />
      }}
      value={searchText}
      onChange={(e) => setSearchText(e.target.value)}
      onSearch={(value) => search(value)}
    >
    </Input.Search>
  )
}

export default LeftMenuSearch