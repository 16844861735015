import { HEATMAP_FOLDER_ITEM_ID } from "@dto/constants/dashboard.constants";
import { Breadcrumb, BreadcrumbProps } from "antd";
import { FC } from "react";
import { FaShare } from "react-icons/fa";
import { Link } from "react-router-dom";
import { CgHeatmapFavorite } from "src/component/breadcrumb/CgHeatmapFavorite";
import { RoutePath } from "src/data/Routes";
import { MenuNode } from "src/model/MenuNode";
import { GLOB } from "src/util/Glob";
import './BreadcrumbXM.less';

const heatmap = 'Heatmap';
const tabHeatmap = '#tab=' + heatmap;
const tabSubHeatmap = `${tabHeatmap}&subTab=${heatmap}`;

export const BreadcrumbXM: FC<{ bread?: BreadcrumbProps }> = props => {
  return <div className="xm-breadcrumb xm-breadcrumb-center">
    <Breadcrumb separator="|" {...props.bread}
      className={` ${props.bread?.className || ''}`}>
      {MenuNode.getParentNodes(GLOB.selectedItem).map((mn, index, array) => <Breadcrumb.Item key={mn.url || mn.key}>
        {index === array.length - 1 ? <a onClick={GLOB.scrollSelectedItemIntoView}>{mn.title}</a>
          : mn.title}
      </Breadcrumb.Item>)}
    </Breadcrumb>
    {GLOB.selectedClass === RoutePath.CUSTOM_GROUPS && (GLOB.location.hash === tabHeatmap || GLOB.location.hash === tabSubHeatmap) && <CgHeatmapFavorite className="xm-bread-side star" />}
    {GLOB.selectedClass === RoutePath.DASHBOARD && GLOB.selectedItem.parent?.itemId === HEATMAP_FOLDER_ITEM_ID
      && <Link className="hover-info xm-bread-side" title="Jump to related page"
        to={`${RoutePath.CUSTOM_GROUPS}/${GLOB.selectedItem.itemId}`}><FaShare /></Link>}
  </div>;
};