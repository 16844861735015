import { ColorScale } from "plotly.js";
import { makeColorScaleFunc } from 'plotly.js/src/components/colorscale/helpers';
import tinycolor from "tinycolor2";

export class ColorUtil {

  /**
   * Graph traces colors
   */
  static colorPalette: string[];

  /**
   * Color CSS value for no data (heatmap, deleted items)
   */
  static readonly NO_DATA_COLOR = 'lightgray';

  /**
   * Array containing arrays mapping a normalized value to an rgb, rgba, hex, hsl, hsv, or named color string.
   * At minimum, a mapping for the lowest (0) and highest (1) values are required.
   */
  static readonly COLOR_SCALE: ColorScale = [[0, 'green'], [0.25, 'lightgreen'], [0.5, 'yellow'], [0.75, 'orange'], [1, 'red']];

  /**
   *  Get scaled color array from COLOR_SCALE for provided value
   */
  static readonly scaleColor: (value: number) => number[] = makeColorScaleFunc({
    domain: (ColorUtil.COLOR_SCALE as [number, string][]).map(value => value[0] * 100),
    range: (ColorUtil.COLOR_SCALE as [number, string][]).map(value => value[1])
  }, { returnArray: true });

  /**
   * Convert color array to rgba string
   * @param colorArray
   * @returns
   */
  static colorArray2rgba(colorArray: number[]) {
    const colorObj = {
      r: colorArray[0],
      g: colorArray[1],
      b: colorArray[2],
      a: colorArray[3]
    };
    return tinycolor(colorObj).toRgbString();
  }

  /**
   * Get contrast color (black or white) for provided color array
   * @param rgb
   * @returns
   */
  static contrastColor(rgb: number[]) {
    const brightness = Math.round(((rgb[0] * 299) +
      (rgb[1] * 587) +
      (rgb[2] * 114)) / 1000);
    return (brightness > 125) ? 'black' : 'white';
  }
}