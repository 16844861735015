import { Item } from '@dto/architecture.dto';
import { useContext } from 'react';
import { GLOB } from 'src/util/Glob';
import { EnableItemId } from '../RulesList';
import { AllItemsContext } from '../comp/AllItems';
import TagsList from '../comp/TagsList';

interface Props {
  onClose: (id: string) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  value?: EnableItemId[] | Item[];
  onChange?: (items: EnableItemId[] | Item[]) => void
};

function TagsBox({ onClose, value }: Props) {
  const { allItems, isLoadingAll } = useContext(AllItemsContext);

  function makeTreeObj(itemTree: EnableItemId | Item) {
    let disabled = false;
    if ('disabled' in itemTree)
      disabled = itemTree.disabled;

    let title: string;
    allItems.some(item => {
      if (item.item_id === itemTree.item_id) {
        title = item.label;
        return true;
      }

      return false;
    });

    return { title, key: itemTree.item_id, disabled };
  }

  return (
    <>
      {!isLoadingAll &&
        <TagsList
          items={value.map(makeTreeObj).sort((a, b) => GLOB.naturalSort(a.title, b.title))}
          onClose={onClose}
        />
      }
    </>
  );
}

export default TagsBox;