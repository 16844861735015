import { Item, ItemGroupPreview, ItemsResponseDTO } from "@dto/architecture.dto";
import { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import { API_URL } from "src/data/Api";
import { isClassLPAR } from "src/data/Class";
import { Log } from "src/service/Log";
import { postApi } from "src/util/apiCalls";
import { AdditionalApiParamsContext, RuleContext } from "../Rule";
import { EnableItemId } from "../RulesList";
import { AllItemsContext } from '../comp/AllItems';
import ModalSelectItems from "../comp/ModalSelectItems";

function ModalSelectItemsExclude() {
  const { cls, subsystem, hw_type, allItems, isLoadingAll } = useContext(AllItemsContext);
  const { parents, linuxExclude, regex, tags } = useContext(RuleContext);
  const [searchedItems, setSearchedItems] = useState<Item[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { exclude } = useContext(AdditionalApiParamsContext);

  useEffect(() => {
    const controller = new AbortController();
    const params = exclude?.select ? { ...exclude.select } : {};  // additionalApiParm.items.select

    postApi<ItemsResponseDTO, ItemGroupPreview>(
      API_URL.ARCHITECTURE + '/preview?rootParent=true',
      {
        class: cls,
        subsystem,
        hw_type: isClassLPAR(cls) ? hw_type : undefined,
        regex,
        tags: tags?.map(t => t.tag_id),
        linuxExclude,
        parents: parents && {
          ...parents,
          regex: parents.regex?.filter(parent => !!parent.subsystem),
          item_ids: parents.items?.map(item => item.item_id),
          tags: parents.tags?.map(tag => ({ tag_id: tag.tag_id, subsystem: tag.subsystem }))
        }
      },
      { signal: controller.signal, params: { rootParent: true, ...params } },
    ).then(
      ({ data: { data } }) => setSearchedItems(data),
      (reason: AxiosError) => Log.error('Failed to retrieve items!', reason)
    ).finally(() => setIsLoading(false));

    return () => controller.abort();
  }, []);

  function makeItemOfList({ item_id, disabled }: EnableItemId) {
    let title: string;
    allItems.some(item => {
      if (item.item_id === item_id) {
        title = item.label;
        return true;
      }

      return false;
    });

    return { title, key: item_id, disabled };
  }

  return (
    <ModalSelectItems<EnableItemId, { items: EnableItemId[] }>
      makeItemOfList={makeItemOfList}
      makeLabel={makeItemLabelRoot}
      isLoading={isLoading && isLoadingAll}
      treeItems={searchedItems}
      handleCheckItem={(item) => ({ item_id: item.item_id, disabled: false })}
    />
  );
}

export function makeItemLabelRoot({ label, root_parent }: Item) {
  return (
    <span>
      {label}
      {root_parent && <span className="xm-item-root-parent">{`  (${root_parent.label})`}</span>}
    </span>
  );
}

export default ModalSelectItemsExclude;