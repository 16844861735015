import { ArrayResponseDTO, BasicResponseDTO } from '@dto/basicResponse.dto';
import { LoginRequestDTO } from '@dto/session.dto';
import { SettingKey } from '@dto/settings.dto';
import { UserResponseDTO } from '@dto/usersResponse.dto';
import { parseBool } from '@dto/util/util';
import { Modal, notification } from 'antd';
import axios, { AxiosError, HttpStatusCode } from 'axios';
import { getEmails } from 'src/component/emailGroups/emailGroupService';
import { ColorUtil } from 'src/util/ColorUtil';
import { GLOB } from 'src/util/Glob';
import { ThemeUtil } from 'src/util/ThemeUtil';
import { postApi } from 'src/util/apiCalls';
import { DEFERRED_STATUS } from 'src/util/deferred';
import { API_URL } from '../data/Api';
import { Log } from '../service/Log';
import { DataService } from './DataService';
import { getSettings } from './SettingService';

export class Auth {

  private _auth = true;

  constructor() {
    axios.interceptors.response.use(undefined, (error) => {
      if (!axios.isCancel(error))
        console.error(error);
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<BasicResponseDTO>
        switch (axiosError.response?.status) {
          case HttpStatusCode.Unauthorized:
            if (this.isLoggedIn()) {
              GLOB.xormonReady.status === DEFERRED_STATUS.resolved && Log.warn('You have been logged out.');
              this.logOut();
            }
            break;
          case HttpStatusCode.PaymentRequired: // license
          case HttpStatusCode.MethodNotAllowed: // demo
            Log.warn(axiosError.response?.data?.message || axiosError.response?.data?.error);
            throw new axios.Cancel(axiosError.response?.data?.error);
          default:
            break;
        }
      }
      return Promise.reject(error instanceof Error ? error : new Error(String(error)));
    });
    setTimeout(() => {
      void this.init();
    });
  }

  private setAuth(auth: boolean) {
    this._auth = auth;
    setTimeout(() => {
      GLOB.setState({ refresh: !GLOB.getState().refresh });
    });
  }

  isLoggedIn() {
    return this._auth;
  }

  private async init(login = false) {

    this.setAuth(true);

    const pUser = axios.get<UserResponseDTO>(API_URL.USER).then(response => {
      GLOB.userInfo = { ...response.data.data };
      void GLOB.infoLoaded.promise.then(() => (!GLOB.getBackendInfo().backend.demo || !ThemeUtil.isThemeStored()) && ThemeUtil.setDarkTheme(!!GLOB.userInfo?.configuration?.darkMode));
    }, (reason: AxiosError) => Log.error('Failed to get user info!', reason));

    const pSet = getSettings(SettingKey.GLOB.PREFIX).then(response => {
      const settings = response.data.data;
      GLOB.graphTopItems = parseInt(settings.GLOB_TOP_ITEMS);
      GLOB.showDeletedItems = parseBool(settings.GLOB_SHOW_DELETED_ITEMS);
    });

    const pPalette = axios.get<ArrayResponseDTO>(API_URL.METRICS + '/pallete').then(response => {
      ColorUtil.colorPalette = response.data.data;
    }, (reason: AxiosError) => Log.error('Failed to get color palette!', reason));

    const pBackend = DataService.checkVersionReload();

    return Promise.all([GLOB.infoLoaded.promise, pUser, pSet, pPalette, GLOB.menuService.init(), GLOB.menuService.hwTypesLoaded.promise, pBackend]).then(() => {
      void getEmails().then(response => {
        GLOB.setEmailGroups(response.data.data);
      });
      setTimeout(() => {
        GLOB.xormonReady.resolve();
      });
    }, (reason: AxiosError) => {
      login && GLOB.xormonReady.reject(reason);
      setTimeout(() => {
        this.logOut();
      });
    });
  }

  async logIn(creds: LoginRequestDTO) {
    return postApi<BasicResponseDTO, LoginRequestDTO>(API_URL.SESSION + '/login', creds).then(response => {

      return this.init(true);

    }, (error: AxiosError<BasicResponseDTO>) => {
      const invalidLogin = (error: string) => {
        Log.error(error);
        return error;
      };

      if (error.response)
        return invalidLogin(error.response.data.message);
      else if (error.message)
        return invalidLogin(error.message);

      return invalidLogin('No response was received!');
    });
  }

  logOut() {
    axios.get<BasicResponseDTO>(API_URL.SESSION + '/logout').then(reponse => {
      // user logged out
    }, (reason: AxiosError) => Log.error('Failed to invalidate session!', reason));
    GLOB.selectedClass = GLOB.selectedType = GLOB.selectedItem = null;
    GLOB.setState({ changeLogModalTimestamp: 0 });
    GLOB.xormonReady.reset();
    Modal.destroyAll();
    notification.close(Log.STATIC_NOTIFICATION_KEY);
    this.setAuth(false);
  }
}
