import { BasicResponseDTO } from "@dto/basicResponse.dto";
import { DashboardGraph, DashboardGroup, UpdateDashboardGraphDTO } from "@dto/dashboard.dto";
import { API_URL } from "src/data/Api";
import { RoutePath } from "src/data/Routes";
import { Log } from "src/service/Log";
import { putApi } from "src/util/apiCalls";
import { GLOB } from "src/util/Glob";
import { MenuDataNode } from "./DashboardContextMenu";

export function makeDashboardDefault(dashboard: MenuDataNode) {
  if (GLOB.userInfo.readonly) return Promise.reject(new Error('Readonly'));
  return putApi(`${API_URL.DASHBOARD}${RoutePath.DASHBOARD}/${dashboard.itemId}/default`)
    .catch((reason) => Log.error(`Failed to make ${dashboard.titleText} default dashboard!`, reason));
}

export async function saveGraph(graph: DashboardGraph, group: DashboardGroup) {
  if (GLOB.userInfo.readonly) return Promise.reject(new Error('Readonly'));
  const dto = new UpdateDashboardGraphDTO(graph);
  dto.dashboard_group_id = group.dashboard_group_id;
  return putApi<BasicResponseDTO, UpdateDashboardGraphDTO>('/api/dashboard/v1/graph/' + graph.dashboard_graph_id, dto)
    .catch((reason) => Log.error('Failed to save graph!', reason));
}