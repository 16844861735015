import { Select, SelectProps } from "antd";
import { BaseOptionType, DefaultOptionType } from "antd/lib/select";
import { ReactElement } from "react";
import { DomUtil } from "src/util/DomUtil";

/**
 * AntD Select with some default values
 * @param props AntD Select properties
 * @returns
 */
export const SelectXM: (<ValueType = any, OptionType extends DefaultOptionType | BaseOptionType = DefaultOptionType>
  (props: SelectProps<ValueType, OptionType>) => ReactElement) = props => {
    return <Select
      getPopupContainer={(triggerNode: HTMLElement) => triggerNode.closest('.ant-modal-content') && triggerNode.parentElement
        || DomUtil.closestScrollable(triggerNode) || triggerNode.closest('.ant-tabs-content') ||
        triggerNode.closest('.xm-base-page') || triggerNode.parentElement}
      dropdownMatchSelectWidth={false}
      optionFilterProp="label"
      {...props} />;
  }
