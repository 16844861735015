import { WebsocketLicenseData } from '@dto/websocketMessage.dto';
import { Menu } from 'antd';
import { PresetStatusColorType } from 'antd/lib/_util/colors';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { MenuItemType, SelectInfo } from 'rc-menu/lib/interface';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { FaCaretRight } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import ErrorBoundary from 'src/content/errorBoundary/ErrorBoundary';
import { API_URL } from 'src/data/Api';
import { RoutePath } from 'src/data/Routes';
import { menuItemsRight, resetMenuItemsRight } from 'src/data/menu-items';
import { useWebSocketLicense } from 'src/hook/useWebSocketReload';
import { MenuNode } from 'src/model/MenuNode';
import { appGlobalSlice } from 'src/redux/appGlobalStore';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { GLOB } from 'src/util/Glob';
import SearchBar from './SearchBar';
import './TopNavBar.less';

const { setTopMenuSelectedKeys, setLicenseStatus } = appGlobalSlice.actions;

// take items and folders
const ITEM_URL = API_URL.CUSTOM_GROUP_MENU;

function hasCG(node: MenuNode) {
  if (!node) return false;
  if (node.url?.startsWith(ITEM_URL)) return true;
  if (node.children?.some(mn => hasCG(mn))) return true;
  return false;
}

function limitStatusToPresetColor(status: WebsocketLicenseData['status']): PresetStatusColorType {
  if (status === 'error') return 'error';
  if (status === 'warn') return 'warning';
  return 'default';
}

function TopNavBar() {

  const limit = useWebSocketLicense();
  const [menu, setMenu] = useState<MenuNode[]>([]);
  const [menuItemsFetched, setMenuItemsFetched] = useState<ItemType[]>([]);
  const [menuItemsStatic, setMenuItemsStatic] = useState<ItemType[]>([]);
  const dispatch = useAppDispatch();
  const appGlobals = useAppSelector(store => store.appGlobals);
  const { showMenu, topMenuSelectedKeys } = appGlobals;
  const location = useLocation();
  GLOB.getMenu = () => menu;
  GLOB.setMenu = setMenu;

  useEffect(() => {
    ErrorBoundary.reset();
  }, [location]);

  useEffect(() => {
    if (!limit) return;
    if (limit.data.type === 'license') {
      dispatch(setLicenseStatus(limitStatusToPresetColor(limit.data.status)));
    }
  }, [limit]);

  useEffect(() => {
    void GLOB.xormonReady.promise.then(() => {
      const left: ItemType[] = GLOB.getMenu().map(mn => {
        switch (mn.path.toLocaleLowerCase()) {
          case RoutePath.STORAGE:
            return {
              key: mn.key, label: <Link to={mn.path + RoutePath.DEVICE}><>{mn.title}</></Link>,
              disabled: mn.notActive,
              children: [
                { key: 'storageDevice', label: <Link to={mn.path + RoutePath.DEVICE}>Devices</Link>, title: 'Storage devices' },
                { key: 'storageGroup', label: <Link to={mn.path + RoutePath.GROUP}>Groups</Link>, title: 'Storage devices by groups' },
                { key: 'storageType', label: <Link to={mn.path + RoutePath.TYPE}>HW Types</Link>, title: 'Storage devices by HW type' },
              ]
            };
          case RoutePath.SAN:
          case RoutePath.LAN_CFG:
            return {
              key: mn.key as string,
              disabled: mn.notActive,
              label: <Link to={mn.path + RoutePath.TYPE}><>{mn.title}</></Link>,
              children: []
            };
          case RoutePath.DASHBOARD:
          case RoutePath.CUSTOM_GROUPS:
            return {
              key: mn.key as string,
              label: <Link to={mn.path + (mn.path === RoutePath.DASHBOARD ? RoutePath.DEFAULT : '')}><>{mn.title}</></Link>,
              disabled: mn.notActive || !mn.children?.length || mn.path === RoutePath.CUSTOM_GROUPS && !hasCG(mn),
              children: mn.children.map(child => {
                if (child.divider) {
                  return { type: 'divider', key: child.key } as MenuItemType;
                }
                return ({
                  key: child.key, label: <Link to={mn.path + GLOB.getItemPath(child)}><>{!child.isLeaf && <FaCaretRight style={{ verticalAlign: 'text-top' }} />}{child.title}</></Link>,
                  className: !child.isLeaf ? 'xm-menu-folder' : null
                }) as MenuItemType
              })
            };
          default:
            return {
              key: mn.key as string, label: <Link to={mn.path + RoutePath.TYPE}><>{mn.title}</></Link>,
              disabled: mn.notActive,
              children: mn.children?.map(child => ({
                key: child.key, label: <Link to={mn.path + child.path}><>{child.title}</></Link>,
              }) as MenuItemType)
            };
        }
      });
      setMenuItemsFetched(left);

      resetMenuItemsRight();
      menuItemsRight[menuItemsRight.length - 1].children[0].title = (
        <span className="text-grayx" title={GLOB.userInfo?.email}>
          {GLOB.userInfo?.username}
        </span>
      );
      const right = filterMenu(menuItemsRight.filter(mn => mn.path !== RoutePath.SEARCH));
      const rightTop: ItemType[] = removeTreeOnly([...right]).map(mn => {
        if (!mn.children)
          return { key: mn.key, icon: mn.icon as ReactNode, style: { float: 'right' } };
        return {
          key: mn.key,
          icon: mn.icon as ReactNode,
          style: { float: 'right' },
          children: mn.children.map(makeChildRight(mn))
        };
      });

      setMenuItemsStatic(rightTop);
      GLOB.menuService.menuFiltered.resolve();
    });
  }, [menu]);

  function removeTreeOnly(nodes: MenuNode[]): MenuNode[] {
    return nodes.filter(i => !i.treeOnly).map(i => {
      if (i.children) {
        return { ...i, children: removeTreeOnly([...i.children]) };
      }
      return i;
    });
  }

  const filterMenu = (nodes: MenuNode[]) => {
    return nodes.filter(mn => (!mn.notInDemo || !appGlobals.info?.backend?.demo)
      && (!mn.notInProduction || !appGlobals.info?.backend.production)
      && (!mn.notOnDocker || !appGlobals.info?.backend.docker)
      && (!mn.blockReadonly || !GLOB.userInfo.readonly)
      && (!mn.adminOnly || GLOB.userInfo.isAdmin)
      && (!mn.adminFeature || (!GLOB.userInfo.readonly && GLOB.userInfo.allowedFeatures[mn.adminFeature]))
      && (!mn.hwType || GLOB.menuService.hwTypes.some(ht => Array.isArray(mn.hwType) ? (mn.hwType.includes(ht.hw_type)) : (ht.hw_type === mn.hwType) && (GLOB.userInfo.isAdmin || ht.allowed)))
      && (!mn.classType || GLOB.menuService.hwTypes.some(ht => ht.class === mn.classType && (GLOB.userInfo.isAdmin || ht.allowed))))
      .map(mn => {
        if (mn.children) {
          mn.children = filterMenu(mn.children);
        }
        return mn;
      }).filter(mn => mn.isLeaf || mn.children?.length || mn.path === RoutePath.SET_LOGS);
  }

  const onMenuSelect = useCallback((info: SelectInfo) => {
    if (info.key === RoutePath.GLOB_HS) {
      GLOB.setState({ isHSOpen: true });
      return;
    }

    dispatch(setTopMenuSelectedKeys(info.selectedKeys.filter(key => key !== RoutePath.GLOB_HS)))
  }, []);

  return (
    <nav className="xm-top-menu" style={{ display: showMenu ? 'flex' : 'none' }}>
      <Menu className='flex xm-menu-classes' mode="horizontal" subMenuCloseDelay={0.2} subMenuOpenDelay={0.3} items={menuItemsFetched}
        selectedKeys={topMenuSelectedKeys} onSelect={onMenuSelect} />
      {showMenu && // rerender
        <div className='xm-menu-slim'>
          {menuItemsFetched.length ? <SearchBar /> : null /** Wait for menu item fetch*/}
          <Menu mode="horizontal" triggerSubMenuAction="click" items={menuItemsStatic}
            selectedKeys={topMenuSelectedKeys} onSelect={onMenuSelect} />
        </div>}
    </nav>
  );
}

const makeChildRight = ({ path }: MenuNode) => (child: MenuNode) => {
  if (child.divider) {
    return {
      type: 'divider',
      key: child.key
    };
  }

  if (child.path) {
    if (!child.children) {
      return {
        key: child.key,
        label: <Link to={(path || '') + child.path}><>{child.title}</></Link>
      }
    }

    if (child.path === RoutePath.HELP) { // move self_monitoring right after Logs
      const monitor = child.children.find(({ path }) => path && path === RoutePath.SET_SELF_MONITORING);
      if (monitor) {
        const newChildenOrder = child.children.filter(({ path }) => path && path !== RoutePath.SET_SELF_MONITORING);
        const logIndex = newChildenOrder.map(({ key }) => key).indexOf(RoutePath.SET_LOGS);

        child.children = [
          ...newChildenOrder.slice(0, logIndex + 1),
          monitor,
          ...newChildenOrder.slice(logIndex + 1)
        ];
      }
    }

    return {
      type: 'group',
      key: child.key,
      label: child.title,
      children: child.children.map(leaf => {
        if (leaf.divider) {
          return {
            type: 'divider',
            key: leaf.key
          };
        }

        return {
          key: leaf.key,
          label: leaf.path ? <Link to={path + child.path + leaf.path}><>{leaf.title}</></Link> : leaf.title
        }
      })
    }
  }

  return {
    type: child.children ? 'group' : null,
    key: child.key,
    label: child.title,
    disabled: child.disabled
  };
}

export default TopNavBar;
