
export abstract class DomUtil {
  /**
     * Waits 1sec for element to appear in specified root DOM
     * @param selector
     * @param root
     * @returns
     */
  static waitForElm(selector, root: HTMLElement) {
    return new Promise<HTMLElement>((resolve, reject) => {
      if (root.querySelector(selector)) {
        return resolve(root.querySelector(selector) as HTMLElement);
      }

      const observer = new MutationObserver(mutations => {
        if (root.querySelector(selector)) {
          resolve(root.querySelector(selector) as HTMLElement);
          observer.disconnect();
        }
      });

      observer.observe(root, {
        childList: true,
        subtree: true
      });

      setTimeout(() => {
        observer.disconnect();
        reject(new Error('Element not found within timeout!'));
      }, 1000);
    });
  }

  /**
   * Gets closest element whose parent is scrollable
   * @param element to start from
   * @returns element or undefined
   */
  static closestScrollable(element: HTMLElement) {
    if (!element?.parentElement) return;
    const parent = element.parentElement;
    const style = getComputedStyle(parent);
    const overflow = style.overflow + style.overflowY;
    if (/(auto|scroll)/.test(overflow))
      return element;
    return DomUtil.closestScrollable(parent);
  }
}