import { AuditItem, AuditItemResponseDTO } from "@dto/auditResponse.dto";
import { RangePickerProps } from "antd/lib/date-picker";
import moment from "moment";
import { useEffect, useState } from "react";
import RangeDatePicker from "src/component/dataTimePicker/RangeDatePicker";
import { ColProps, GridScope, IGridEntry } from 'src/component/grid/Grid';
import useDebounce from "src/hook/useDebounce";
import useGetApiData from "src/hook/useGetApiData";
import { DateUtil } from "src/util/DateUtil";
import { GLOB } from "src/util/Glob";
import { TextUtil } from "src/util/TextUtil";

interface TableData extends AuditItem {
  inSeconds: number;
  rowKey: number;
}

const AuditLog = () => {
  const [gs] = useState(() => new GridScope<TableData>());
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [apiParams, setApiParams] = useState<{ start: Date, end: Date }>(oneDayRange());
  const debouncedApiParams = useDebounce(apiParams);
  const [isTimeSet, setIsTimeSet] = useState(true);
  const [data, , loading] = useGetApiData<AuditItem[], AuditItemResponseDTO>(
    `/api/audit/v1?start=${toSeconds(apiParams.start)}&end=${toSeconds(apiParams.end)}`,
    "Get Logs failed",
    [debouncedApiParams],
    []
  );

  useEffect(() => {
    const result: TableData[] = [];
    data.forEach((event, value) => {
      const time = new Date(event.time);
      result.push({ ...event, rowKey: value, inSeconds: toSeconds(time), time: DateUtil.getDateISO(time) })
    })

    setTableData(result);
  }, [data]);

  const columns: ColProps<IGridEntry & TableData>[] = [
    { /// User
      title: "User",
      dataIndex: "user",
      key: "user",
      minWidth: '6em',
      filters: data.map(log => log.user).filter((element, index) => (data.map(log => log.user).indexOf(element) === index)).map(user => ({ text: user, value: user })),
      onFilter: (value, record) => value === record.user
    },
    { /// User
      title: "IP",
      dataIndex: "ip",
      key: "ip",
      minWidth: '6em',
    },
    { /// Time
      title: "Time",
      dataIndex: "time",
      key: "time",
      minWidth: "12em",
      sorter: (a, b) => a.inSeconds - b.inSeconds,
      defaultSortOrder: "descend"
    },
    { /// Event
      title: "Event",
      dataIndex: "event",
      key: "event",
      minWidth: "11em",
      sorter: (a, b) => a.event.localeCompare(b.event),
      filter: { field: ['event'] }
    },
    { /// Affected
      title: "Affected",
      dataIndex: "affected",
      key: "affected",
      minWidth: "11em",
      onCell: (() => ({ style: { minWidth: '3em' } })),
      sorter: (a, b) => a.event.localeCompare(b.event),
      render: (text: string) => TextUtil.trimMiddle(text),
      trim: true
    },
  ];

  const setRowKey = (record: TableData) => record.rowKey;
  const handleRangeChange: RangePickerProps['onChange'] = (values) => {
    if (!values) {
      setIsTimeSet(false);
      return;
    }

    setIsTimeSet(true);
    setApiParams({ start: values[0].toDate(), end: values[1].toDate() });
  };

  return (
    <div className="audit-container">
      <gs.Grid
        key="audit-table"
        rowKey={setRowKey}
        className="audit-table xm-table-fit"
        loading={loading}
        columns={columns}
        dataSource={tableData}
        pagination={false}
        scroll={{ y: GLOB.getState().mainScrollRef.current.clientHeight - 50 }}
      />
      <div className="audit-time-range-wrapper">
        <div className="audit-time-range">
          <div className="audit-time-range-header">
            <div>Time range</div>
          </div>
          <RangeDatePicker
            className={!isTimeSet ? 'error-select' : ''}
            defaultValue={[moment(apiParams.start), moment(apiParams.end)]}
            onChange={handleRangeChange}
          />
        </div>
      </div>
    </div>
  );
}

const toSeconds = (time: Date) => (Math.round(time.getTime() / 1000));
const oneDayRange = (): { start: Date, end: Date } => {
  const end = new Date();
  end.setSeconds(0, 0);

  const start = new Date(end);
  start.setDate(start.getDate() - 1);

  return { start: start, end: end };
};

export default AuditLog;