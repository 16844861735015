import { ItemGroupPreview } from "@dto/architecture.dto";
import { isClassLPAR } from "src/data/Class";
import { ApiPrams } from "../../Rule";
import { RuleGroup } from "../../RulesList";
import { UsePreviewModal } from "./Preview";

type CgPreviewHandlerType = (
  cls: string,
  hwType: string,
  subsystem: string,
  showModal: ReturnType<UsePreviewModal>[0],
  additionalApiParams?: ApiPrams
) => (rules: RuleGroup[]) => () => void;

const cgPreviewHandler: CgPreviewHandlerType = (cls, hwType, subsystem, showModal, additionalApiParams = {}) => (rules) => () => {
  const includeIds: string[] = [];
  const regexNew: ItemGroupPreview[] = [];

  rules.forEach(({ items, tags, regex, parents, exclude }) => {
    if (items) {
      includeIds.push(...items.filter(item => !item.disabled && !includeIds.includes(item.item_id)).map((item) => item.item_id))
      return;
    }

    if (tags) {
      regexNew.push(makePreviewApiData(cls, hwType, subsystem, parents, regex, tags?.map(t => t.tag_id), false, exclude));
      return;
    }

    if (regex.length)
      regexNew.push(makePreviewApiData(cls, hwType, subsystem, parents, regex, undefined, false, exclude));
  });

  showModal({
    cls: cls,
    hw_type: hwType,
    subsystem,
    data: regexNew,
    additionalApiParams,
    includeIds: includeIds.length ? includeIds : undefined
  });
};

export function makePreviewApiData(
  cls: string,
  hwType: string,
  subsystem: string,
  parents: RuleGroup['parents'],
  regex?: string[],
  tags?: string[],
  linuxExclude = false,
  exclude?: RuleGroup['exclude'],
): ItemGroupPreview {
  return {
    class: cls,
    hw_type: isClassLPAR(cls) ? hwType : undefined,
    subsystem,
    regex,
    tags,
    linuxExclude,
    parents: parents && {
      intersection: parents.intersection,
      regex: parents.regex?.filter(parent => !!parent.subsystem),
      tags: parents.tags?.map(({ tag_id, subsystem }) => ({ tag_id, subsystem })),
      item_ids: parents.items?.map(item => item.item_id),
    },
    exclude: exclude && {
      regex: exclude.regex,
      tags: exclude.tags?.map(({ tag_id }) => tag_id),
      item_ids: exclude.items?.map(item => item.item_id),
    }
  };
}

export default cgPreviewHandler;