import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { useContext, useEffect } from 'react';
import { AllItemsContext } from '../comp/AllItems';
import { AllTagsContext } from '../comp/AllTags';
import { makePreviewApiData } from '../comp/preview/CgPreviewHandler';
import { usePreviewModal } from '../comp/preview/Preview';
import TagSelect from '../comp/TagSelect';
import { invalidHwProps, RuleContext } from '../Rule';
import { GLOB } from 'src/util/Glob';

interface Props {
  formItemName?: (string | number)[];
}

function TagList({ formItemName = ['tags'] }: Props) {
  const { cls, subsystem, hw_type } = useContext(AllItemsContext);
  const { fetchStarted, startFetch } = useContext(AllTagsContext);
  const { ruleIndex, tags: tagsTmp, exclude, linuxExclude, parents } = useContext(RuleContext);
  const invalidBaseProps = invalidHwProps({ cls, hw_type, subsystem });
  // const [showModal, contextHolder] = usePreviewModalTag<PreviewTableTagProps>();
  const [showPreview, contextHolder] = usePreviewModal(linuxExclude);

  const tags = formItemName.includes('exclude') ? exclude.tags : tagsTmp;

  useEffect(() => {
    if (!fetchStarted)
      startFetch();
  }, []);

  const handlePreview = (tag: string) => () => showPreview({
    cls,
    subsystem,
    hw_type,
    data: makePreviewApiData(
      cls,
      hw_type,
      subsystem,
      formItemName.includes('exclude') ? undefined : parents,
      undefined,
      [tag],
      linuxExclude,
    ),
  });

  const getDependencies = dependenciesMaker(ruleIndex);
  const noSameTag = sameTagRuleMaker(tags);

  const { readonly } = GLOB.userInfo;

  return <>
    <Form.List name={[ruleIndex, ...formItemName]}>
      {(tagsItems, { remove }) => {
        return (
          <div className='xm-rule-filter-tag'>
            {tagsItems.map(({ key, name }, indx) => (
              <span className='xm-tag-item' key={`parent-tag-list-${key}`}>
                <span className='xm-tag-item-container'>
                  <Form.Item
                    name={[name, 'tag_id']}
                    dependencies={getDependencies(indx, tagsItems.length)}
                    help={<></>}
                    rules={[
                      { required: true },
                      noSameTag(indx)
                    ]}
                  >
                    <TagSelect selectProps={{ className: 'xm-tag-select' }} />
                  </Form.Item>
                  <Button
                    disabled={invalidBaseProps || !tags[name].tag_id}
                    size='small'
                    icon={<EyeOutlined />}
                    onClick={handlePreview(tags[name].tag_id)}
                  />
                </span>
                <Button
                  size='small'
                  className={readonly || invalidBaseProps || tags.length < 2 ? 'xm-disabled-delete' : 'hover-danger'}
                  disabled={readonly || invalidBaseProps || tags.length < 2}
                  type='link'
                  title='Remove tag'
                  icon={<DeleteOutlined onClick={() => remove(name)} />}
                />
              </span>
            ))}
          </div>
        )
      }}
    </Form.List>
    {contextHolder}
  </>;
}

export const dependenciesMaker = (ruleIndex: string | number, parents = false) => (indx: number, length: number) => {
  const deps: NamePath<(string | number)[]>[] = [];
  const start = (typeof ruleIndex != 'number') ? [ruleIndex] : ['rules', ruleIndex];
  if (parents)
    start.push('parents');

  for (let i = 0; i < length; i++) {
    if (indx != i)
      deps.push([...start, 'tags', i, 'tag_id']);
  }

  return deps;
};

const sameTagRuleMaker = (tags: {
  tag_id: string;
  tag: string;
}[]) => (tagIndx: number) => {
  const validator = (_, val: string) => {
    console.log({ tags }, val);
    if (tags.some((tag, indx) => tag.tag_id == val && indx !== tagIndx))
      return Promise.reject(new Error(`Tag with ID ${val} already exists.`));

    return Promise.resolve();
  }

  return { validator }
};

export default TagList;