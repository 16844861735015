import { ConfigurationChange } from '@dto/configurationChanges.dto'
import { DatePicker } from 'antd'
import { SortOrder } from 'antd/lib/table/interface'
import moment, { Moment } from 'moment'
import { FC, ReactNode, useState } from 'react'
import {
  ColProps,
  FilterType,
  GridScope,
  IGridEntry,
} from 'src/component/grid/Grid'
import { DomUtil } from 'src/util/DomUtil'
import { ComponentProps } from './CFGTracker'

const { RangePicker } = DatePicker;

interface Props extends ComponentProps {
  showTechnologyColumn?: boolean;
  children?: ReactNode
}

export const CFGTrackerTable: FC<Props> = ({ items, onRangeChange, showTechnologyColumn, range, isLoading, children }) => {
  const [gs] = useState(() => new GridScope<ConfigurationChange>());
  const [divNode, setDivNode] = useState<HTMLDivElement>();
  const columnsFiltered = showTechnologyColumn ? columns : columns.filter(column => column.key !== 'hw_type');

  const handleDateFilterChange = (dates: Moment[]) => {
    if (dates.length !== 2) {
      onRangeChange();
      return;
    }

    const [start, end] = dates;
    onRangeChange({ start, end });
  };

  return (
    <div ref={r => r ? setDivNode(r) : null} className='xm-cfg-tracker-table'>
      <div>
        <RangePicker
          className='xm-cfg-tracker-picker table'
          showTime
          defaultValue={range}
          format='YYYY-MM-DD HH:mm:ss'
          onChange={handleDateFilterChange}
        />
        {children}
      </div>
      {divNode && <gs.Grid
        loading={isLoading}
        style={{ minWidth: '200px', marginLeft: 4 }}
        dataSource={items}
        rowKey={record => record.item_id + record.timestamp + record.property}
        columns={columnsFiltered}
        scroll={{ x: true, y: DomUtil.closestScrollable(divNode).clientHeight - 75 }}
      />}
    </div>
  );
};

const columnProps = (dataIndex: keyof ConfigurationChange): ColProps<ConfigurationChange & IGridEntry> => ({
  dataIndex,
  key: dataIndex,
  filter: { field: [dataIndex], type: 'string' as FilterType },
  sorter: (a: ConfigurationChange, b: ConfigurationChange) =>
    a[dataIndex].localeCompare(b[dataIndex]),
});

const columns: ColProps<ConfigurationChange & IGridEntry>[] = [
  {
    title: 'Timestamp',
    dataIndex: 'timestamp',
    key: 'timestamp',
    defaultSortOrder: 'descend' as SortOrder,
    sorter: (a: ConfigurationChange, b: ConfigurationChange) =>
      a.timestamp.localeCompare(b.timestamp),
    render: (text: Date) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
    // TODO: needed?
    // filterIcon: () => (
    //   <CalendarOutlined
    //     style={{
    //       color: dateRange?.length === 2 ? '#3c6c84' : undefined,
    //     }}
    //   />
    // ),
    // filterDropdown: () => (
    //   <div style={{ padding: 8 }}>
    //     <RangePicker
    //       showTime
    //       format='YYYY-MM-DD HH:mm:ss'
    //       onChange={handleDateFilterChange}
    //       value={dateRange}
    //     />
    //   </div>
    // ),
    // onFilter: (_, record: ConfigurationChange) => {
    //   if (!dateRange || dateRange.length !== 2) return true

    //   const recordDate = moment(record.timestamp)
    //   return recordDate.isBetween(startDate, endDate, null, '[]')
    // },
  },
  {
    title: 'Item',
    ...columnProps('label')
  },
  {
    title: 'Root Parent',
    ...columnProps('root_parent')
  },
  {
    title: 'Technology',
    ...columnProps('hw_type')
  },
  {
    title: 'Subsystem',
    ...columnProps('subsystem')
  },
  {
    title: 'Property',
    ...columnProps('property')
  },
  {
    title: 'Old Value',
    ...columnProps('old_value')
  },
  {
    title: 'New Value',
    ...columnProps('new_value')
  },
];