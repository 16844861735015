import { HistoryOutlined, QuestionCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { KeyboardCode } from "@dnd-kit/core";
import { BasicResponseDTO } from "@dto/basicResponse.dto";
import { RecentlySearchedResponseDTO } from "@dto/recentlySearched.dto";
import { SearchItemResponseDTO } from "@dto/searchResponse.dto";
import { TagsWithValueIndicatorResponseDTO } from "@dto/tag.dto";
import { TagValuesResponseDTO } from "@dto/tagValue.dto";
import { AutoComplete, AutoCompleteProps, Button, Input, InputProps, InputRef, Tooltip } from "antd";
import axios, { AxiosError, AxiosResponse } from "axios";
import { ChangeEvent, forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { API_URL } from "src/data/Api";
import useDebounce from 'src/hook/useDebounce';
import { Log } from 'src/service/Log';
import 'src/styles/_variables.less';
import { GLOB } from "src/util/Glob";
import { getApi } from "src/util/apiCalls";
import { Action, Options, useSearchReducer } from "../reducer/useSearchReducer";

const CAT_TAG_REGEX = /(#|tag:)(.+):/i;

let tagOpts: Options[] = [];
let catOpts: Options[] = [];
let lastOpts: Options[] = [];
let lastSelectTimestamp = 0;

let catAC: AbortController;

const SearchBar = () => {
  const { state, dispatch } = useSearchReducer();
  const { keepInput, onIcon, search, options, onSearch } = state;
  const location = useLocation();
  const { pathname, search: searchByLocation } = location;
  const [prevLocation, setPrevLocation] = useState(location);
  const navigate = useNavigate();
  const inputR = useRef<InputRef>();
  const iconR = useRef<HTMLDivElement>();
  const inputWrapper = useRef<HTMLDivElement>();
  const searchLower = search.toLowerCase();
  const isRegex = searchLower.startsWith('re:');
  const isTag = searchLower.startsWith('#') || search.startsWith('tag:');
  const isCategory = isTag && CAT_TAG_REGEX.test(search);
  const search4debounce = isRegex ? '' : search;
  const debouncedSearch = useDebounce<string>(search4debounce);

  if (location !== prevLocation) { // reset searched text on URL change
    setPrevLocation(location);
    // dispatch({ type: Action.resetText });
  }

  useEffect(() => {
    void getLastSearch()
  }, [])

  useEffect(() => {
    let ac: AbortController;
    if (isTag) {
      ac = new AbortController();
      getApi<TagsWithValueIndicatorResponseDTO>(API_URL.TAG as string, ac.signal).then(resp => {
        const prefix = search.startsWith('#') ? '#' : 'tag:';
        tagOpts = resp.data.data.map(t => ({
          key: t.tag_id,
          label: prefix + t.label + (t.include_self ? '' : ':'),
          value: prefix + t.label + (t.include_self ? '' : ':*'),
          link: 'tag'
        }));
        setOptions(tagOpts)
      }, (reason: AxiosError) => Log.error('Failed to get tag list!', reason));
    }
    return () => {
      ac?.abort();
    }
  }, [isTag]);

  const useQuery = (): [string, URLSearchParams] => useMemo(() => [pathname, new URLSearchParams(searchByLocation)], [searchByLocation]);

  const [, query] = useQuery();
  const searchTitle = query.has("find") && query.get("find");
  useEffect(() => {
    if (searchTitle) {
      dispatch({ type: Action.type, payload: searchTitle });
    }
  }, [searchTitle])

  const getLastSearch = async () => {
    const response = await axios.get<RecentlySearchedResponseDTO>(`/api/search/v1/recently_searched?limit=1&user_id=${GLOB.userInfo.user_id}`)
    const { data } = response.data
    if (data?.length > 0) {
      dispatch({ type: Action.type, payload: data[0]?.search_query })
    }
  }

  useEffect(() => {
    if (keepInput)
      inputR.current?.focus();
  }, [keepInput]);

  useEffect(() => {
    if (!isTag && (!debouncedSearch || debouncedSearch.length <= 2 || debouncedSearch.startsWith('wwn:') || debouncedSearch.startsWith('ip:') || debouncedSearch.startsWith('mac:'))) // search option only on text with length at least 3 characters
      return;

    const controller = new AbortController();
    const onResolve = ({ data: { data } }: AxiosResponse<SearchItemResponseDTO>) => {
      if (!Array.isArray(data)) {
        if (!data.items) {
          setOptions();
          return;
        }

        setOptions(data.items.map(item => ({
          value: item.label,
          label: item.label,
          key: item.item_id,
          link: (item.class) ? GLOB.menuService.getRouteLink(item.url) : "alert"
        })))
      }
    };

    const api = `/api/search/v1/item/${encodeURIComponent(debouncedSearch)}?page=0&limit=10`;
    getApi<SearchItemResponseDTO>(api, controller.signal).then(resp => {
      if (resp?.data?.data?.items?.length)
        onResolve(resp);
      else if (isCategory) {
        const match = CAT_TAG_REGEX.exec(search);
        const prefix = match[0];
        const tag = search.substring(prefix.length);
        getCategory(tag);
      } else if (isTag) {
        setOptions(tagOpts.filter(o => o.value.toLowerCase().startsWith(search.toLowerCase())));
      } else {
        setOptions([]);
      }
    },
      () => setOptions([])
    );

    return () => controller.abort();
  }, [debouncedSearch]);

  function getCategory(prefix: string) {
    catAC?.abort();
    catAC = new AbortController();
    const tag_label = CAT_TAG_REGEX.exec(search)?.[2];
    getApi<TagValuesResponseDTO>(API_URL.TAG + '/value/name', catAC.signal, { params: { tag_label, ci: true } }).then(resp => {
      catOpts = resp.data.data.map(t => ({ key: t.tag_value_id, label: t.value, value: t.value, link: 'tag' }));
      const prefixLower = prefix.toLowerCase()
      setOptions(catOpts.filter(o => o.value.toLowerCase().startsWith(prefixLower)))
    }, (reason: AxiosError) => Log.error('Failed to get category tags!', reason));
  };

  function setOptions(payload: Options[] = []) {
    lastOpts = payload;
    dispatch({ type: Action.setOpt, payload });
  }

  const goToPage = (opt?: Options) => {
    if (opt?.link === 'alert') {
      Log.info(`Item is missing class ${search}.`);
      return;
    }

    let path = `/search?find=${encodeURIComponent(search)}`;
    if (opt?.link === 'tag') {
      let toFind = opt.value;
      if (isCategory) {
        const prefix = CAT_TAG_REGEX.exec(search)[0];
        toFind = prefix + opt.value;
      }
      dispatch({ type: Action.type, payload: toFind });
      path = `/search?find=${encodeURIComponent(toFind)}`;
    }

    navigate(path);
    dispatch({ type: Action.redirect });
  }

  const handlePressEnter = async () => {
    if (GLOB?.userInfo?.user_id && search?.trim().length > 0) {
      try {
        await axios.post(`/api/search/v1/recently_searched/add?user_id=${GLOB?.userInfo?.user_id}&search_query=${encodeURIComponent(search)}`)
        goToPage();
      } catch (error) {
        const axiosError = error as AxiosError<BasicResponseDTO>
        Log.error(axiosError.response.data.message, axiosError)
      }
    } else {
      dispatch({ type: Action.type, payload: '' });
    }
  }

  const onInputKey: AutoCompleteProps['onInputKeyDown'] = (e) => {
    setTimeout(() => {
      if (e.key === KeyboardCode.Enter && lastSelectTimestamp < (Date.now() - 1000)) {
        void handlePressEnter();
      }
    });
  }
  const handleSelect: AutoCompleteProps['onSelect'] = (_: string, opt: Options) => {
    lastSelectTimestamp = Date.now();
    goToPage(opt);
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => dispatch({ type: Action.type, payload: e.target.value });
  const handleClickIn = () => {
    //goToPage();
    void handlePressEnter();
  }

  const handleClickOut: React.FocusEventHandler<HTMLDivElement> = ({ relatedTarget }) => {
    if (relatedTarget && inputWrapper.current.contains(relatedTarget) || onIcon || relatedTarget?.className?.includes("help-regex-link"))
      return;

    dispatch({ type: Action.hide });
  };

  const handleRedirect = () => {
    dispatch({ type: Action.redirect });
  };

  const handleClickIcon = () => {
    dispatch({ type: Action.keepInput });
    dispatch({ type: Action.onIcon });
  };

  if (!keepInput) {
    return <>
      <div className="search-input" />
      <SearchIcon
        className="xm-search-icon"
        onClick={handleClickIcon}
        ref={iconR}
      />
    </>;
  }

  const handleFocusInput = () => inputR.current?.focus();

  const handleSearchHistory = () => {
    const path = `/search`;
    navigate(path);
    // dispatch({ type: Action.redirect });
    // dispatch({ type: Action.type, payload: '' });
  };

  const onInputClick: InputProps['onClick'] = e => {
    setOptions(lastOpts);
  }

  return <AutoComplete
    popupClassName="whisper"
    onSelect={handleSelect}
    onInputKeyDown={onInputKey}
    options={options}
    dropdownStyle={{ marginTop: (iconR.current.offsetHeight + 6) + "px" }}
    className="xm-search-open-wrapper"
  >
    <div className="search-content-wrapper" tabIndex={1} onClick={handleFocusInput}>
      <div className="search-input"
        ref={inputWrapper}
        tabIndex={1}>
        <Input
          onBlur={handleClickOut}
          onChange={handleChange}
          //onPressEnter={handlePressEnter}
          onClick={onInputClick}
          ref={inputR}
          placeholder="Search..."
          value={search}
          addonBefore={<Button onClick={handleSearchHistory} type="link" icon={<HistoryOutlined />} style={{ height: 'auto' }} />}
          addonAfter={
            <Tooltip overlayStyle={{ maxWidth: '600px' }} placement={'bottomRight'} mouseLeaveDelay={0.5} title={
              <span>
                Search options<br />
                1) Text: start typing searched string (e.g. &#39;vm01&#39;)<br />
                2) <a onClick={handleRedirect} className="help-regex-link" href='https://xormon.com/regular_expression.php' target="_blank" rel="noopener noreferrer">Regular expression</a>: type &quot;re:&quot; followed by regular expression (e.g. &quot;re:^vm[0-9]+$&quot;)<br />
                3) Tag: type &quot;tag:&quot; or &#35; followed by Tag or Category:Tag (e.g. &quot;tag:Production&quot; or &#35;Region:eu-central)<br />
                4) IP: type &quot;ip:&quot; followed by IP (e.g. &quot;ip:127.0.0.1&quot;)<br />
                5) MAC: type &quot;mac:&quot; followed by MAC (e.g. &quot;mac:00:1a:2b:3c:4d:5e&quot;)<br />
                6) WWN: type &quot;wwn:&quot; followed by WWN (e.g. &quot;wwn:10:00:00:05:1e:7a:7a:00&quot;)<br />
                7) Volume ID: type &quot;volid:&quot; followed by volume ID (e.g. &quot;volid:6005076300808AE5100000000000001A&quot;)<br />
                View more details about search options in <a onClick={handleRedirect} className="help-regex-link" href="https://xormon.com/search-application.php" target="_blank" rel="noopener noreferrer">online documentation</a>
              </span>
            }>
              <QuestionCircleOutlined className={'xm-search-help'} />
            </Tooltip>

          }
        />
      </div>
      <SearchIcon key='search-show'
        className={(keepInput || onSearch) ? "xm-search-icon xm-search-icon-active" : "xm-search-icon"}
        onClick={handleClickIn}
        onMouseLeave={() => dispatch({ type: Action.onIcon, payload: false })}
        onMouseEnter={() => dispatch({ type: Action.onIcon, payload: true })}
        ref={iconR}
      />
    </div>
  </AutoComplete>;
}

const SearchIcon = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(function SearchIcon(props, ref) {
  return <div {...props} ref={ref}>
    <SearchOutlined />
  </div>
});

export default SearchBar;
