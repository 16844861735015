import { WebsocketGrafanaData, WebsocketLicenseData, WebsocketMessage, WebsocketReloadData } from "@dto/websocketMessage.dto";
import { useEffect, useState } from "react";
import useWebSocketSingleton from "./useWebSocketSingleton";

const WS_RELOAD_URL = '/websocket/reload';

function isReload(ws: WebsocketMessage): ws is WebsocketMessage<WebsocketReloadData> {
  return ws?.event && ws.event === 'reload';
}

function isLicense(ws: WebsocketMessage): ws is WebsocketMessage<WebsocketLicenseData> {
  return ws?.event && ws.event === 'license';
}

function isGrafana(ws: WebsocketMessage): ws is WebsocketMessage<WebsocketGrafanaData> {
  return ws?.event && ws.event === 'grafana';
}

export const useWebSocketGrafana = () => {
  const [data, setData] = useState<boolean>();
  const reload = useWebSocketSingleton(WS_RELOAD_URL);

  useEffect(() => {
    if (isGrafana(reload))
      setData(reload.data.active);
  }, [reload]);

  return data;
}

export const useWebSocketLicense = () => {
  const [data, setData] = useState<WebsocketMessage<WebsocketLicenseData>>();
  const reload = useWebSocketSingleton(WS_RELOAD_URL);

  useEffect(() => {
    if (isLicense(reload))
      setData(reload);
  }, [reload]);

  return data;
}

export const useWebSocketReload = (reloadType: WebsocketReloadData['type']) => {
  const [data, setData] = useState<WebsocketMessage<WebsocketReloadData>>();
  const reload = useWebSocketSingleton(WS_RELOAD_URL);

  useEffect(() => {
    if (isReload(reload) && reload.data.type === reloadType)
      setData(reload);
  }, [reload, reloadType]);

  return data;
}

export const useWebSocketReloadGetRandomNumberOnReload = (reloadType: WebsocketReloadData['type']) => {
  const [data, setData] = useState<number>();
  const reload = useWebSocketSingleton(WS_RELOAD_URL);

  useEffect(() => {
    if (isReload(reload) && reload.data.type === reloadType)
      setData(Math.random() * 1000);
  }, [reload, reloadType]);

  return data;
}
