import { Subsystem } from "@dto/architecture.dto";
import { ConfigurationChange, ConfigurationChangesDTO } from "@dto/configurationChanges.dto";
import { ConfigurationChangesBox } from "@dto/pageResponse.dto";
import { SettingKey } from "@dto/settings.dto";
import { Button, Card, InputNumber, Tabs } from "antd";
import moment, { Moment } from "moment";
import { FC, useEffect, useRef, useState } from "react";
import { Help } from "src/component/help/Help";
import TabsSticky from "src/component/stickyTab/TabsSticky";
import { Log } from "src/service/Log";
import { GLOB } from 'src/util/Glob';
import { getSettings, saveSettings } from "src/service/SettingService";
import { getApi } from "src/util/apiCalls";
import { getAllSubsystems } from "../alerting/alertingService";
import { CFGTrackerTable } from "./CFGTrackerTable";
import './CfgTracker.less';

interface ISubsystem extends Subsystem {
  hw_type_label?: string
}

export interface Tab {
  label: string;
  key: string;
  items: ConfigurationChange[];
}

export interface ComponentProps {
  items: ConfigurationChange[];
  range: [Moment, Moment],
  onRangeChange: (range?: { start: Moment, end: Moment }) => void;
  isLoading: boolean;
}

// Can be same ref for all instances of cfgTrackerWrapper
let hwTypeLabels: ISubsystem[] = [];

export default function cfgTrackerWrapper(Component: React.ComponentType<ComponentProps>) {
  function Wrapper({ box }: Partial<{ box: ConfigurationChangesBox }>) {
    const [items, setItems] = useState<ConfigurationChange[]>();
    const [isLoading, setIsLoading] = useState(true)
    const [range, setDateRange] = useState<[Moment, Moment]>([
      moment().subtract(7, 'days'),
      moment()
    ]);

    useEffect(() => {
      const controller = new AbortController();
      void (async function () {
        if (!hwTypeLabels.length) {
          const { data: { data: tmpHwTypeLabels } } = await getAllSubsystems();
          hwTypeLabels = tmpHwTypeLabels as ISubsystem[];
          if (!hwTypeLabels.length)
            return;
        }

        const startInSec = Math.floor(range[0].valueOf() / 1000);
        const endInSec = Math.floor(range[1].valueOf() / 1000);
        getApi<ConfigurationChangesDTO>(`/api/configuration/v1/events`, controller.signal, {
          params: {
            start: startInSec,
            end: endInSec,
            hw_type: box?.hw_type,
            subsystem: box?.subsystem,
            item_ids: box?.item_ids?.join(',')
          }
        }).then(
          ({ data: { data } }) => {
            const dataWithLabels = data.map((obj): ConfigurationChange => ({
              ...obj,
              hw_type: hwTypeLabels.find((el) => el.hw_type === obj.hw_type)?.hw_type_label,
              subsystem: hwTypeLabels.find((el) => el.subsystem === obj.subsystem && el.hw_type === obj.hw_type)?.label,
            }))

            setItems(dataWithLabels);
          },
          error => Log.error('Failed to fetch configuration changes', error)
        ).finally(() => setIsLoading(false));
      })();

      return () => controller.abort();
    }, [range]);

    const handleRangeChange = (range?: { start: Moment, end: Moment }) => {
      if (range)
        setDateRange([range.start, range.end])
      else
        setDateRange([moment().subtract(7, 'days'), moment()]);
    };

    return <Component items={items} onRangeChange={handleRangeChange} isLoading={isLoading} range={range} />;
  };

  Wrapper.displayName = 'CfgTrackerWrapper';
  return Wrapper;
}

export const CFGTrackerWithTabs: FC<ComponentProps> = ({ items, isLoading, onRangeChange, range }) => {
  const [prevItems, setPrevItems] = useState<ConfigurationChange[]>();
  const menuData = useRef<Tab[]>([]);

  if (items !== prevItems && !isLoading) {
    setPrevItems(items);
    menuData.current = [
      { key: 'all', label: 'All', items },
      ...[...new Set(items.map(obj => obj.hw_type))].map(
        val => ({
          key: val,
          label: val,
          items: items.filter(item => item.hw_type == val)
        })
      )
    ];
  }

  return (
    <TabsSticky
      items={[{
        key: 'default',
        label: 'CFG Tracker',
        className: 'xm-cfg-tracker xm-cfg-tracker-top-menu',
        children:
          <Tabs
            defaultActiveKey='all'
            className='xm-cfg-tracker-left-menu'
            tabPosition="left"
            items={menuData.current.map(item => ({
              ...item,
              children:
                <CFGTrackerTable
                  items={item.items}
                  isLoading={isLoading}
                  showTechnologyColumn={item.key == 'all'}
                  onRangeChange={onRangeChange}
                  range={range}
                >
                  <Help href='https://xormon.com/CFG-Tracker.php' style={{
                    top: 9,
                    right: 0,
                    position: "absolute",
                  }} />
                </CFGTrackerTable>
            }))}
          />
      },
      {
        key: "options",
        label: "Options",
        className: "xm-cfg-tracker-top-menu",
        children: <SetRetentionModal />
      }
      ]}
    />
  );
}

function SetRetentionModal() {
  const [retention, setRetention] = useState<number>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    const keys = SettingKey.GLOB;
    getSettings(keys.PREFIX, controller).then(response => {
      const initRetention = response.data.data.GLOB_CONF_CHANGES_RETENTION;
      setRetention(+initRetention);
    }).catch(
      error => Log.error("Failed to obtain Retention value!", error)
    ).finally(() => setIsLoading(false));

    return () => controller.abort();
  }, []);

  const handleChange = (newRetention: number) => setRetention(newRetention);
  const handleOk = () => {
    setIsLoading(true);
    void saveSettings({ 'GLOB_CONF_CHANGES_RETENTION': retention.toString() }).then(
      () => {
        Log.info('Settings Retention value updated');
      },
      error => Log.error('Failed to save Retention value!', error)
    ).finally(() =>
      setIsLoading(false)
    );
  };

  const {readonly} = GLOB.userInfo;

  return (
    <Card title="Retention">
      <span>Set retention: </span>
      <InputNumber placeholder='' min={1} disabled={readonly || isLoading} value={retention} onChange={handleChange} />
      <span> days</span>
      <Button type="primary" className="xm-btn-retention" onClick={handleOk} disabled={readonly || isLoading}>Save</Button>
    </Card>
  );
}