import { DASHBOARD_TYPE } from '@const/dashboard.constants';
import { BasicResponseDTO } from './basicResponse.dto';
import { MenuItem } from './menuResponse.dto';
import { GraphBase, GraphInterval } from './pageResponse.dto';

export type DashboardType = typeof DASHBOARD_TYPE[keyof typeof DASHBOARD_TYPE];

export class DashboardDTO {
  dashboard_id: string;
  label: string;
  type: DashboardType;
  groups: DashboardGroupDTO[];
  folder: string;
  default: boolean;
  width: number;
}

export class DefaultDashboard {
  dashboard: DashboardDTO;
  path: MenuItem[];
}

export class DefaultDashboardDTO extends BasicResponseDTO {
  data: DefaultDashboard;
}

export class DashboardGroupDTO {
  dashboard_group_id: string;
  dashboard_id?: string;
  label: string;
}

export class DashboardGroupsDTO extends BasicResponseDTO {
  data: DashboardGroupDTO[];
}

export class DashboardGraph extends GraphBase {
  // so it is placed to end before compact
  static readonly DEFAULT_COORDINATE = 99;
  label: string;
  interval: GraphInterval;
  width: number;
  height: number;
  url: string;
  x: number;
  y: number;
  dashboard_graph_id: string;
  tab: string;
  subtab?: string;

  static equals(a: DashboardGraph, b: DashboardGraph) {
    return a.tab === b.tab && a.interval === b.interval && a.url === b.url;
  }
}

export class DashboardGroup {
  graphs: DashboardGraph[];
  label: string;
  width: number;
  height: number;
  x: number;
  y: number;
  dashboard_group_id: string;
}

export class DashboardFolder {
  dashboard_folder_id: string;
  label: string;
  type: DashboardType;
}

export class CreateDashboardGraphDTO {
  dashboard_group_id: string;
  url: string;
  tab: string;
  subtab?: string;
  interval: GraphInterval;
}

export class CreateGrafanaGraphDTO {
  grafana_dashboard_uid: string;
  url: string;
  tab: string;
  subtab?: string;
  interval: GraphInterval;
}

export class GrafanaTreeItem {
  type: 'folder' | 'dashboard';
  uid: number;
  label: string;
  children?: GrafanaTreeItem[];
}


export class FindDashboardGraphDTO {
  url: string;
  tab: string;
  subtab?: string;
  interval?: GraphInterval;
}

export class CreateDTO {
  label: string;
  parent: string;
  group_id?: number;
}

export class UpdateDashboardDTO {
  label?: string;
  parent?: string;
  group_id?: number;
  width?: number;
}

export class UpdateDashboardFolderDTO {
  label?: string;
  parent?: string;
  group_id?: number;
}

export class CreateResponseDTO extends BasicResponseDTO {
  data: {
    id: string;
    label?: string;
    parent?: string;
  };
}

export class FindGraph {
  interval: GraphInterval;
  dashboard_graph_id: string;
  dashboard_group: {
    label: string;
    dashboard_group_id: string;
  };
  dashboard: {
    label: string;
    dashboard_id: string;
  };
  group_id?: number;
}

export class FindGraphsDTO extends BasicResponseDTO {
  data: FindGraph[];
}

export class FindGrafanaGraph {
  dashboard: {
    grafana_dashboard_uid: string;
    label: string;
  }
  grafana_graph_uid: string;
  interval: GraphInterval;
}

export class FindGrafanaGraphsDTO extends BasicResponseDTO {
  data: FindGrafanaGraph[];
}

export class UpdateDashboardGraphDTO {
  width?: number;
  height?: number;
  url?: string;
  x?: number;
  y?: number;
  interval?: GraphInterval;
  tab?: string;
  subtab?: string;
  dashboard_group_id: string;

  constructor(graph: DashboardGraph) {
    this.width = graph.width;
    this.height = graph.height;
    this.url = graph.url;
    this.x = graph.x;
    this.y = graph.y;
    this.interval = graph.interval;
  }
}

export class UpdateDashboardGroupDTO {
  width: number;
  height: number;
  x: number;
  y: number;
  label: string;
  dashboard_id: string;

  constructor(group: DashboardGroup) {
    this.width = group.width;
    this.height = group.height;
    this.x = group.x;
    this.y = group.y;
    this.label = group.label;
  }
}

export class GroupDashboardFolder extends MenuItem {
  group_id: number;
}

export class GroupDashboardFoldersDTO extends BasicResponseDTO {
  data: GroupDashboardFolder[];
}

export class GrafanaTreeDTO extends BasicResponseDTO {
  data: GrafanaTreeItem[];
}