import React from "react";

interface Change {
  version: string;
  content: React.ReactElement
}

/**
 * Array ordered by version
 */
export const CHANGES: Change[] = [
  {
    version: '1.8',
    content: <div>
      <h3>v1.8.0 (20th Feb 2025)</h3>

      <h4>Support for new technologies</h4>
      <ul>
        <li>IBM Z Mainframe</li>
        <li>Veritas NetBackup</li>
        <li>ExaGrid Tiered Backup Storage</li>
        <li>iXsystems FreeNAS, TrueNAS</li>
        <li>Hitachi Virtual Storage Platform One (VSP One) Block</li>
        <li>Fujitsu ETERNUS</li>
      </ul>

      <h4>Feature enhancements</h4>
      <ul>
        <li><a href="https://xormon.com/grafana.php" target="_blank">Grafana</a> integration</li>
        <li>Filtering in the left tree menu</li>
        <li>Reporter: ability to exclude items from selections</li>
        <li><a href="https://xormon.com/filesystem-monitoring.php" target="_blank">Filesystem usage</a> graphs (total,used,free), integration with alerting, reporting and exporting</li>
        <li><a href="https://xormon.com/users.php" target="_blank">Users</a>: read-only role, user locking</li>
        <li>SSL communication support between the OS agent and XorMon NG server (use -x when you running the OS agent)</li>
        <li>VMware: mapping table: VM disks ➡ datastore and storage devices</li>
        <li>IBM i OS agent: implemented support for reporting, exporting and alerting</li>
        <li>Storage capacity prediction graphs: implemented total capacity per storage device (previously only per pool prediction was available)</li>
        <li>Volume multipath: storage volumes assigned to VMs are visible in the "Volume" VM tab</li>
        <li>Item Select: ability to exclude items from already selected set of items</li>
        <li>UI <a href="https://xormon.com/search-application.php" target="_blank">Search</a> feature enhanced with IP, MAC, WWN and Volume ID search capabilities</li>
        <li>Dell EMC Power MAX: support for 10.2+ firmwares</li>
        <li>Tag support in the REST API</li>
        <li>SSL communication support between the OS agent and XorMon NG server (use -x when you running the OS agent)</li>
        <li>OS agent sends MAC and WWN of all interfaces, they are accessible through the UI Search feature</li>
        <li>Broadcom SANnav: events and zoning setup are presented in the application</li>
        <li>IBM FlashSystem / Storwize / SVC: storage firmware 8.7+ support, older XorMon NG versions do not support it</li>
        <li>IBM Flash System / Storwize: support for <a href="https://xormon.com/copyservices-monitoring.php" target="_blank">copy services</a> (mirroring)</li>
      </ul>

      <h4>Many fixes especially for</h4>
      <ul>
        <li>IBM Storage Protect (TSM): include many smaller enhancements</li>
        <li>Hitachi HCP</li>
        <li>VMware</li>
        <li>Hitachi VSP</li>
      </ul>

      <h4>Licensing changes</h4>
      <ul>
        <li>Storage: 8 storage devices limit (it was 12)</li>
        <li>Backup devices: 4 devices limit (it was 1)</li>
      </ul>

    </div>
  },
  {
    version: '1.7',
    content: <div>
      <h3>v1.7.0 (10th Dec 2024)</h3>

      <h4>Support for new technologies</h4>

      <ul>
        <li>IBM Cloud</li>
        <li>IBM Storage Protect (formerly IBM Spectrum Protect, TSM)</li>
        <li>IBM DS8000</li>
        <li>Ceph</li>
        <li>IBM Storage Defender Data Protect</li>
        <li>HPE Alletra MP</li>
      </ul>

      <h4>Feature enhancements</h4>

      <ul>
        <li><a href="https://xormon.com/dark-mode.php" target="_blank">UI dark mode</a> as a user option</li>
        <li><a href="https://xormon.com/search.php" target="_blank">UI search</a> per IP address</li>
        <li><a href="https://xormon.com/CFG-Tracker.php" target="_blank">CFG Tracker</a>: easy tracking all configuration changes mainly on VMs, containers and volumes</li>
        <li><a href="https://xormon.com/IBM-Power-SAN-Topology.php" target="_blank">IBM Power Topology</a>: LPAR ➡ VIO ➡ FC adapter ➡ SAN switch</li>
        <li><a href="https://xormon.com/VMware-Topology.php" target="_blank">VMware Topology</a>: schemas are presented on cluster, datastore and VM levels</li>
        <li><a href="https://xormon.com/Nutanix-Topology.php" target="_blank">Nutanix Topology</a> schemas</li>
        <li>Nutanix: capacity per cluster, host and disk</li>
        <li>VMware: TOP Datastore: Used Space % added</li>
        <li>Microsoft Azure agent 2 support</li>
        <li><a href="https://xormon.com/vm-migration.php" target="_blank">VM migration</a> CPU graphs for IBM Power System, Nutanix and Proxmox</li>
        <li><a href="https://xormon.com/alerting.php#builder" target="_blank">Alerting</a>: new API for user defined connections to 3rd party tools</li>
        <li><a href="https://xormon.com/graphing.php" target="_blank">Graphs</a>: switch between stacked and line graphic mode in each graph</li>
        <li>Filesystem global tables per technology</li>
        <li>ACL enhancements, possibility to export/import ACL rules, rules are available via the REST API</li>
        <li>EMC Unity: total is now block + FS (before FS was not considered in total)</li>
        <li>Dell EMC PowerMAX: <a href="https://xormon.com/SAN_Topology-storage.php" target="_blank">Topology</a> support</li>
        <li>Dell EMC Isilon: add node table to configuration page</li>
        <li>Dell Compellent: add back-end statistics for Pool (IO, DATA)</li>
        <li><a href="https://xormon.com/copyservices-monitoring.php" target="_blank">Copy services</a> (mirroring) support for:</li>
        <ul>
          <li>HPE 3PAR / Alletra 9000 / Alletra MP</li>
          <li>Huawei OceanStor / Dorado</li>
          <li>IBM DS8000</li>
        </ul>
        <li>Significant speed up of loading aggregated graphs with many items (3 - 6x faster now)</li>
      </ul>

      Many other small changes and bug fixes were done.
    </div>
  },
  {
    version: '1.6',
    content: <div>
      <h3>v1.6.0 (19th Sep 2024)</h3>
      The main enhancements include support for new monitored devices and further feature improvements.
      <h4>Support for new technologies</h4>
      <ul>
        <li>IBM Power Enterprise Pools 2.0 (PEP2.0)</li>
        <li>oVirt / Red Hat Virtualization (RHV) / OLVM</li>
        <li>Solaris CDOM / LDOM / Zone</li>
        <li>Broadcom (Brocade) SANnav</li>
        <li>Synology</li>
        <li>Dell EMC² Unity</li>
        <li>DataCore SANsymphony</li>
        <li>Huawei OceanStor Pacific</li>
        <li>Huawei OceanStor Pacific NAS</li>
        <li>Huawei OceanProtect Backup Storage</li>
      </ul>
    </div>
  },

] as const;