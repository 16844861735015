import { Form, InputNumber } from 'antd';
import { useEffect, useRef, useState } from "react";
import { LazyLog } from '@melloware/react-logviewer';
import { Spinner } from 'src/component/spinner/Spinner';
import { DateUtil } from 'src/util/DateUtil';
import { GLOB } from "../../../util/Glob";
import './Logs.less';

interface Props {
  viewerData: (linesCount: number, controller: AbortController) => Promise<string>;
  path?: string;
}

const LogViewer = ({ viewerData, path }: Props) => {
  const [data, setData] = useState<string>(' ');
  const [linesCount, setLinesCount] = useState(50);
  const lastUpdate = useRef<string>();
  const [trigger, setTrigger] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    const timeout = window.setTimeout(() => {
      void viewerData(linesCount, controller).then((response) => {
        setData(response);
        setTrigger(trigger => !trigger)
        lastUpdate.current = DateUtil.getDateISO();
      }).finally(() => setLoading(false));
    }, lastUpdate.current ? GLOB.TIMEOUT_LOG : 100);

    return () => {
      clearTimeout(timeout);
      controller.abort();
    };
  }, [linesCount, trigger]);

  const handleLinesChange = (e: number) => setLinesCount(e);

  return (
    <div className="xm-logs">
      <Form layout='inline'>
        <Form.Item label="Number of log lines displayed" >
          <InputNumber
            min={1}
            max={5000}
            value={linesCount}
            step={10}
            onChange={handleLinesChange}
            disabled={!data}
            size='small'
            width={40}
          />
        </Form.Item>
        {path && <span>{path}</span>}
        <span>
          {loading ? <Spinner className='xm-log-loading' /> : <span>{lastUpdate.current ? `Last updated: ${lastUpdate.current}` : 'No data'}</span>}
        </span>
      </Form>
      <div className='lazy-log-container' >
        <LazyLog text={data} overscanRowCount={1}  enableSearch={true} selectableLines={true} />
      </div>
    </div>
  );
}

export default LogViewer;


