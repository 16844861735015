
export enum API_URL {
  ALERTING = '/api/alerting/v1',
  ALERTING_INTEGRATIONS = '/api/alerting/v1/integrations/',
  ARCHITECTURE = '/api/architecture/v1',
  CONFIGURATION = '/api/configuration/v1',
  CUSTOM_GROUP = '/api/custom_group/v1',
  CUSTOM_GROUPS = '/api/custom_group/v1/custom_groups',
  CUSTOM_GROUP_MENU = '/api/custom_group/v1/menu',
  CUSTOM_GROUP_MENU_GROUP = '/api/custom_group/v1/menu/groups',
  DASHBOARD = '/api/dashboard/v1',
  DASHBOARD_FIND_GRAPH = '/api/dashboard/v1/graph/find',
  DASHBOARD_GRAFANA = '/api/dashboard/v1/grafana',
  DASHBOARD_GRAPH = '/api/dashboard/v1/graph',
  DASHBOARD_MENU = '/api/dashboard/v1/menu',
  DASHBOARD_MENU_GROUP = '/api/dashboard/v1/menu/groups',
  EMAIL_GROUPS = '/api/configuration/v1/mail_groups',
  EXPORTER = '/api/exporter/v1/exports',
  FABRIC = '/api/configuration/v1/fabric',
  HEATMAP = '/api/heatmap/v1',
  INFO = '/api/info',
  SELF_MONITORING = '/api/configuration/v1/monitoring',
  LICENSE = '/api/configuration/v1/license',
  LOG_ERR = '/api/log/ui',
  MENU = '/api/menu/v1',
  MENUGROUP = '/api/configuration/v1/menugroup',
  METRICS = '/api/metrics/v1',
  MICROSERVICES = '/api/microservices',
  OS_AGENT = '/api/menu/v1/page/linux/status',
  SESSION = '/api/session/v1',
  STORAGE_GROUPS = '/api/menu/v1/folder/sgroups',
  STORAGE_GROUP_GROUPS = '/api/menu/v1/folder/group_sgroups',
  SERVER_GROUPS = '/api/server_groups/v1',
  SERVER_GROUPS_FOLDER = '/api/menu/v1/folder/servergroups',
  TAG = '/api/tag/v1',
  TOKEN = '/api/token/v1',
  USER = '/api/users/v1/user'
}