import { MenuItem } from '@dto/menuResponse.dto';
import axios from 'axios';
import { GLOB } from 'src/util/Glob';

export enum LEFT_SEARCH {
  SEARCH_URL = '/api/menu/v1/search',
}


export async function searchMenuGET(search: string): Promise<MenuItem[]> {
  if (search.length < 3) {
    throw new Error('At least 3 characters');
  }
  const _class = GLOB.selectedClass.slice(1);
  const hw_type = GLOB.selectedType ? GLOB.selectedType.slice(1) : null;

  const response = await axios.get(`${LEFT_SEARCH.SEARCH_URL}/${_class}?searchText=${search}${hw_type && !["storage", "san", "lan"].includes(_class) ? `&hw_type=${hw_type}` : ''}`);
  return response.data.data;
}

