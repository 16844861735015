import { BackendResponseDTO } from "@dto/backendResponse.dto";
import { PresetStatusColorType } from "antd/lib/_util/colors";
import axios, { AxiosError } from "axios";
import dayjs from "dayjs";
import { MutableRefObject } from "react";
import { API_URL } from "src/data/Api";
import { GLOB } from "src/util/Glob";
import { Log } from "./Log";

export class DataService {
  //TODO: preload classes, hwtypes...
  // //TODO: only 1 websocket in app! reconnect management
  //static readonly socket: Socket<WsEventsFromServer> = io(`${GLOB.SERVER_WS_URL}/websocket/reload/`, { transports: ['websocket', 'webtransport', 'polling'] });
  //static readonly ws = new WebSocket(GLOB.SERVER_WS_URL + '/websocket/reload');

  static {
    // DataService.socket.on('connect', () => console.log('socket connected'));
    // DataService.socket.on('connect_error', err => console.log('socket error', err));
  }

  /**
   * Checks if backend is on different version than UI, reloads page if so
   * @returns 'reload' if reloading, 'error' on fail, undefined otherwise
   */
  static checkVersionReload(): Promise<'reload' | 'error'> {
    const preVer = GLOB.getBackendInfo?.()?.backend.version;
    GLOB.infoLoaded.reset();
    return axios.get<BackendResponseDTO>(API_URL.INFO).then((response) => {
      const dto = response.data.data;
      GLOB.setBackendInfo(dto);
      GLOB.infoLoaded.resolve();
      if (preVer && dto.backend.version !== preVer) {
        DataService.reloadUI();
        return 'reload';
      }
    }, (reason: AxiosError) => {
      Log.error('Failed to get app info!', reason);
      GLOB.infoLoaded.reject(reason);
      return 'error';
    });
  }

  static reloadUI() {
    Log.warn('You are on different version of Xormon, reloading...');
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  /**
   * Timeout for {@linkcode DataService.invokeOnSharedInterval}
   */
  static readonly sharedTimeout: MutableRefObject<number> = { current: 0 };
  /**
   * Invokes callback after each interval, use {@link DataService.sharedTimeout} to clear timeout
   * @param callback function
   * @param interval timeout in ms, default {@link GLOB.TIMEOUT_SHORT}
   * @returns timeout ref
   */
  static invokeOnSharedInterval(callback: () => void, interval = GLOB.TIMEOUT_SHORT) {
    window.clearTimeout(DataService.sharedTimeout.current);
    DataService.sharedTimeout.current = window.setTimeout(() => {
      callback?.();
      DataService.invokeOnSharedInterval(callback, interval);
    }, interval);
    return DataService.sharedTimeout;
  }

  static detectLicenseColor(): PresetStatusColorType {
    const techno = GLOB.getBackendInfo()?.backend?.premium?.technologies;
    if (!techno?.length) return 'default';

    const dates = techno?.map(lt => dayjs(lt.expirationDate));
    const oldest = dates.reduce((prev, cur) => cur.isBefore(prev) ? cur : prev);
    const now = dayjs();

    if (oldest.isBefore(now)) return 'error';
    if (oldest.diff(now, 'M') < 2) return 'warning';
    return 'default';
  }

  // constructor(){
  //   this.ws.onopen = () => setIsConnected(true);
  //   this.ws.onclose = (event) => {
  //     // TODO: to use this, it is necessary to sync BE&FE usage of WS
  //     // if (!event.wasClean && event.reason)
  //     //   Log.error(`Close of WebSocket '${url}' was unexpected.`, event.reason);

  //     setIsConnected(false);
  //   }
  //   this.ws.onmessage = (event) => setData(JSON.parse(event.data));
  // }

}