export const UNIT = {
  bytes: 'bytes',
  bytes_per_second: 'bytes_per_second',
  io_per_second: 'io_per_second',
  IOPS: 'IOPS',
  cpu_core: 'cpu_core',
  cpu: 'cpu',
  vcpu: 'vcpu',
  hertz: 'hertz',
  percent: 'percent',
  millisecond: 'millisecond',
  second: 'second',
  quantity: 'quantity',
  timestamp: 'timestamp',
  crc_errors_per_second: 'crc_errors_per_second',
  errors_per_second: 'errors_per_second',
  packets: 'packets',
  packets_per_second: 'packets_per_second',
  status_in_running_or_not_running: 'status_in_running_or_not_running',
  count: 'count',
  frames: 'frames',
  frames_per_second: 'frames_per_second',
  bb_credits: 'bb_credits',
  crc_errors: 'crc_errors',
  link_fail: 'link_fail',
  loss_sync: 'loss_sync',
  pcs_errors: 'pcs_errors',
  enc_out: 'enc_out',
  disc_c3: 'disc_c3',
  sfp_power_out: 'sfp_power_out',
  sfp_power_in: 'sfp_power_in',
  phase: 'phase',
  net_per_second: 'net_per_second',
  compression_factor: 'compression_factor',
  slices_per_second: 'slices_per_second',
  sources_per_second: 'sources_per_second',
  megabytes_per_second: 'megabytes_per_second',
  gibibytes: 'gibibytes',
  tebibytes: 'tebibytes',
  multiples: 'multiples',
  rows: 'rows',
  sessions: 'sessions',
  blocks: 'blocks',
  temperature: 'temperature',
  unit_per_second: 'unit_per_second',
  ratio: 'ratio',
  OPS: 'OPS',
  watt: 'watt',
  transactions_per_sec: 'transactions_per_sec',
  days: 'days',
  pages_per_second: 'pages_per_second',
  blocks_per_second: 'blocks_per_second',
  hits_per_second: 'hits_per_second',
  misses_per_second: 'misses_per_second',
  decibel_milliwatts: 'decibel_milliwatts',
  jobs: 'jobs'

} as const;

export const SHORTCUT = {
  [UNIT.bytes]: 'B',
  [UNIT.bytes_per_second]: 'B/s',
  [UNIT.io_per_second]: 'IOPS',
  [UNIT.IOPS]: 'IOPS',
  [UNIT.cpu_core]: 'CPU core',
  [UNIT.cpu]: 'CPU',
  [UNIT.vcpu]: 'vCPU',
  [UNIT.hertz]: 'Hz',
  [UNIT.percent]: '%',
  [UNIT.millisecond]: 'ms',
  [UNIT.second]: 's',
  [UNIT.quantity]: '',
  [UNIT.timestamp]: 'timestamp',
  [UNIT.crc_errors_per_second]: 'errors/s',
  [UNIT.errors_per_second]: 'errors/s',
  [UNIT.packets]: 'packets',
  [UNIT.packets_per_second]: 'packets/s',
  [UNIT.status_in_running_or_not_running]: 'housekeep status',
  [UNIT.count]: '',
  [UNIT.frames]: 'frames',
  [UNIT.frames_per_second]: 'frames/s',
  [UNIT.bb_credits]: 'BB credits',
  [UNIT.crc_errors]: 'CRC errors',
  [UNIT.link_fail]: 'link failures',
  [UNIT.loss_sync]: 'synchronization loss',
  [UNIT.pcs_errors]: 'PCS errors',
  [UNIT.enc_out]: 'encoding errors',
  [UNIT.disc_c3]: 'disc C3 frames',
  [UNIT.sfp_power_out]: 'dBm',
  [UNIT.sfp_power_in]: 'dBm',
  [UNIT.phase]: 'phase',
  [UNIT.net_per_second]: 'net',
  [UNIT.compression_factor]: 'compression factor',
  [UNIT.slices_per_second]: 'slices/s',
  [UNIT.sources_per_second]: 'sources/s',
  [UNIT.multiples]: 'multiples',
  [UNIT.rows]: 'rows/s',
  [UNIT.sessions]: 'sessions',
  [UNIT.blocks]: 'blocks/s',
  [UNIT.temperature]: '°C',
  [UNIT.unit_per_second]: 'units/s',
  [UNIT.ratio]: 'ratio',
  [UNIT.OPS]: 'OPS',
  [UNIT.watt]: 'Watt',
  [UNIT.transactions_per_sec]: 'transactions/s',
  [UNIT.days]: 'Day',
  [UNIT.pages_per_second]: 'pages/s',
  [UNIT.blocks_per_second]: 'blocks/s',
  [UNIT.hits_per_second]: 'hits/s',
  [UNIT.misses_per_second]: 'misses/s',
  [UNIT.decibel_milliwatts]: 'dBm',
  [UNIT.jobs]: 'count',
} as const;
